// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';
import { AxiosError } from 'axios';

// <!-- UTILITIES -->
import { Result } from 'true-myth/dist/result';
import { ErrorResult } from '@/utils/server';

// <!-- MODELS -->
import { Note } from '@/models/v2/notes';

// <!-- ROUTES -->

/** @type {Readonly<Note.Routes>} */
const URI = {
    index: (account) => `accounts/${account}/notes`,
    show: (account, id) => `accounts/${account}/notes/${id}`,
    create: (account) => `accounts/${account}/notes`,
    update: (account, id) => `accounts/${account}/notes/${id}`,
    delete: (account, id) => `accounts/${account}/notes/${id}`,
};

/**
 * Fetch account notes.
 * @type {Note.API.FetchIndex}
 */
export const fetchNotes = async (request, config) => {
    try {
        // Prepare the route.
        const endpoint = URI.index(request.account_id);

        /** @type {Note.Response.FetchedIndex} */
        const response = await axios().get(endpoint, config);
        const index = [...response.data.data];
        const models = index.map(Note.Model.indexFromPayload);
        return Result.ok(models);
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Fetch note by its id.
 * @type {Note.API.FetchResource}
 */
export const fetchNoteById = async (request, config) => {
    try {
        // Prepare the route.
        const endpoint = URI.show(request.account_id, request.id);

        /** @type {Note.Response.FetchedResource} */
        const response = await axios().get(endpoint, config);
        const payload = Note.Payload.create(response.data.note);
        const model = payload.toModel();
        return Result.ok(model);
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Create a new note.
 * @type {Note.API.CreateResource}
 */
export const createNote = async (request, config) => {
    try {
        // Prepare the route.
        const endpoint = URI.create(request.account_id);

        /** @type {Note.Response.CreatedResource} */
        const response = await axios().post(endpoint, request, config);
        const payload = Note.Payload.create(response.data.note);
        const model = payload.toModel();
        return Result.ok(model);
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Update a specific note by id.
 * @type {Note.API.UpdateResource}
 */
export const updateNoteById = async (request, config) => {
    try {
        // Prepare the route.
        const endpoint = URI.update(request.account_id, request.id);

        /** @type {Note.Response.UpdatedResource} */
        const response = await axios().patch(endpoint, request, config);
        const payload = Note.Payload.create(response.data.note);
        const model = payload.toModel();
        return Result.ok(model);
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Delete a specific note by id.
 * @type {Note.API.DeleteResource}
 */
export const deleteNoteById = async (request, config) => {
    try {
        // Prepare the route.
        const endpoint = URI.delete(request.account_id, request.id);

        /** @type {Note.Response.DeletedResource} */
        const response = await axios().delete(endpoint, config);
        const result =
            response.status === 204 ? Result.ok(true) : Result.err(response);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/** @type {Readonly<Note.API>} */
export default {
    fetchNotes,
    fetchNoteById,
    createNote,
    updateNoteById,
    deleteNoteById,
};
