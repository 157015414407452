<template>
    <Panel>
        <template #header>
            <div class="flex flex-row justify-between">
                <div class="text-2xl text-black font-bold">
                    {{ title }}
                </div>
            </div>
        </template>
        <template #default>
            <transition
                :appear="true"
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0"
                enter-to-class="transform opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="transform opacity-100"
                leave-to-class="transform opacity-0"
            >
                <NoteFields
                    :sourceNote="sourceNote"
                    :isEditing="isEditing"
                    :isLoading="isLoading"
                    @cancel="$emit('cancel', $event)"
                    @submit="$emit('submit', $event)"
                    :debug="debug"
                />
            </transition>
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import NoteFields from '~NoteManager/components/fields/NoteFields.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AddNoteForm',
        components: {
            Panel,
            NoteFields,
        },
        props: {
            sourceNote: {
                /** @type {import('vue').PropType<Note.Form.AddData>} */
                type: Object,
            },
            isEditing: {
                /** @type {import('vue').PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {import('vue').PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            debug: {
                /** @type {import('vue').PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: ['cancel', 'submit'],
        setup(props) {
            /** @type {String} Form header. */
            const title = `Add Note`;
            return {
                title,
            };
        },
    });
</script>
