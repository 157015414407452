/**
 * @class
 * Debug object.
 */
export class DebugObject {
    /**
     * Create labelled data entry.
     * @param {String} label
     * @param {any} data
     * @param {any} id
     */
    static create(label, data, id) {
        return new DebugObject({ label, data, id });
    }

    /**
     * Construct debug object with labelled entry.
     * @param {{ label?: String, data: any, id?: any }} payload
     */
    constructor(payload) {
        const { label, data, id } = payload;
        Object.assign(this, data);
        this.label = label;
        this.id = id ?? data?.id;
    }

    /**
     * Update label.
     * @param {String} value
     */
    setLabel(value) {
        this.label = value;
    }

    /**
     * Update data value.
     * @param {any} value
     */
    setData(value) {
        this.data = value;
    }
}

/**
 * Prepare a debug frame.
 * @param {{ startHidden?: Boolean, isEnabled?: Boolean, data?: any[] }} attributes
 */
export const useDebugFrame = (attributes = {}) => {
    // INITIAL
    const { isEnabled = true, startHidden = true, data = [] } = attributes;

    // STATE
    const debug = {
        /** @type {Boolean} */
        startHidden: startHidden === true,
        /** @type {Boolean} */
        isEnabled: isEnabled === true && process.env.NODE_ENV !== 'production',
        /** @type {any[]} */
        data: data ?? [],
    };

    // EXPOSE
    return debug;
};

export default useDebugFrame;
