// <!-- API -->
import { ref, computed } from 'vue';
import { createEventHook, resolveUnref } from '@vueuse/shared';
import { fetchNotes } from '@/api/v2/notes';

// <!-- COMPOSABLES -->
import { useLoader } from '@/hooks/status';

// <!-- CLASS -->

/**
 * Define a Note index managing class.
 */
class Index {
    /**
     * Define the event hooks.
     */
    static defineEvents() {
        // Event Hooks.
        /** @type {Vue.EventHook<{ data: Note.Model[] }>} */
        const succeed = createEventHook();
        /** @type {Vue.EventHook<{ reason?: unknown }>} */
        const failed = createEventHook();

        return {
            succeed,
            failed,
        };
    }

    /**
     * Instantiate instance.
     * @param {Note.Model[]} initialState
     */
    constructor(initialState = []) {
        const { succeed, failed } = Index.defineEvents();

        // Event Triggers.
        const success = succeed.trigger;
        const error = failed.trigger;

        // Event Listeners.
        this.onSuccess = succeed.on;
        this.onError = failed.on;

        // Services.
        this.loader = useLoader();

        // Reactivity.
        /** @type {Vue.Ref<Note.Model[]>} */
        const notes = ref(initialState);

        /** @type {Vue.ComputedRef<Map<integer,Note.Model>>} */
        this.state = computed(() => {
            const entries = notes.value.map(
                (item) =>
                    /** @type {[ id: integer, item: Note.Model ]} */ ([
                        item.id,
                        item,
                    ])
            );
            const table = new Map(entries);
            return table;
        });

        // Methods
        /**
         * Set the notes from a list.
         * @param {Note.Model[]} list
         */
        this.fromList = (list) => {
            notes.value = [...list];
        };

        /**
         * Set the notes from a map.
         * @param {Map<integer,Note.Model>} map
         */
        this.fromMap = (map) => {
            this.fromList([...map.values()]);
        };

        /** Clear the index. */
        this.clear = () => this.fromList([]);

        /**
         * Fetch the notes for the specified account.
         * @param {number} account
         */
        this.fetchByAccountId = async (account) => {
            try {
                // Start the loader.
                this.loader.start();

                // Prepare and send request.
                const request = { account_id: account };
                const result = await fetchNotes(request);
                const data = result.isOk ? result.value : [];

                // Check if request was successful.
                if (result.isErr) {
                    // Throw the error, if caught.
                    throw result.error;
                } else {
                    // Send the success event.
                    success({ data });
                }

                // Return the fetched index.
                return this.state.value;
            } catch (e) {
                // Send the error event.
                error({ reason: e });
            } finally {
                // Always stop the loader.
                this.loader.stop();
            }
        };

        // Lifecycle
        this.onSuccess(({ data }) => {
            this.fromList(data);
        });

        this.onError(({ reason }) => {
            console.error(reason);
        });
    }
}

// <!-- COMPOSABLE -->

/**
 * Prepare independent index suite.
 * @param {Vue.Ref<Note.Model[]> | Note.Model[]} [initialState]
 */
export const useNoteIndex = (initialState = []) => {
    const props = resolveUnref(initialState);
    const index = new Index(props);
    return index;
};

// <!-- DEFAULT -->
export default useNoteIndex;
