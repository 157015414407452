// <!-- INTERFACES -->
import { PayloadResource } from '@/utils/resources';
import { PayloadAttributeFactory } from '@/utils/resources';
import { NoteModel } from '@/models/v2/notes/NoteModel';

// <!-- ENUMS -->
import { AssociationType } from '@/enums';
import { Location } from '@/models/v2/locations';

/**
 * Create a specialized resource instance.
 *
 * @extends {PayloadResource<PayloadData,ModelData>}
 * @implements {Resource.Payload<PayloadData,ModelData>}
 * @implements {PayloadData}
 */
export class NotePayload extends PayloadResource {
    // -----------------------------------------------------
    // TYPE ALIASES
    // -----------------------------------------------------

    /** @typedef {Note.Payload} PayloadData */
    /** @typedef {Note.Model} ModelData */

    // -----------------------------------------------------
    // STATIC FACTORIES
    // -----------------------------------------------------

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} attributes
     * @returns {NotePayload}
     */
    static create(attributes) {
        return new NotePayload(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} data
     * @returns {NotePayload}
     */
    static fromModel(data) {
        const attributes = NotePayload.attributesFromModel(data);
        return NotePayload.create(attributes);
    }

    /**
     * Create an index resource.
     * @param {Partial<ModelData>} model
     * @returns {Note.Payload.Index}
     */
    static indexFromModel(model) {
        const { serialize } = this;
        return {
            id: model.id,
            title: model.title,
            content: model.content,
            account_id: model.accountId,
            author_id: model.authorId,
            start_date: serialize.dateAsISOString(model.startDate),
            end_date: serialize.dateAsISOString(model.endDate),
            created_at: serialize.dateAsISOString(model.createdAt),
            updated_at: serialize.dateAsISOString(model.updatedAt),
            association_type: model.associationType,
            association_path: model.associationPath,
            parent_location_id: model.parentLocationId,
            parent_hierarchy_id: model.parentHierarchyId,
            is_visible: model.visible === true,
        };
    }

    // -----------------------------------------------------
    // STATIC PROPERTIES
    // -----------------------------------------------------

    /** @type {Readonly<PayloadData>} */
    static get defaults() {
        return {
            id: undefined,
            title: undefined,
            content: undefined,
            account_id: undefined,
            account: undefined,
            author_id: undefined,
            author: undefined,
            start_date: undefined,
            end_date: undefined,
            created_at: undefined,
            updated_at: undefined,
            association_type: AssociationType.None,
            association_path: '%%%%',
            association_hierarchy: [],
            parent_location_id: null,
            parent_location: null,
            parent_hierarchy_id: null,
            parent_hierarchy: null,
            is_visible: true,
        };
    }

    // -----------------------------------------------------
    // STATIC UTILITIES
    // -----------------------------------------------------

    /**
     * Create resource attributes from the model data.
     *
     * @param {Partial<ModelData>} model
     * @returns {Partial<PayloadData>}
     */
    static attributesFromModel(model) {
        // Get transformer functions.
        const { serialize, authorFromModel, hierarchyFromModel } = this;

        // Return the created state.
        return {
            id: model.id,
            title: model.title,
            content: model.content,
            account_id: model.accountId,
            account: model.account,
            author_id: model.authorId,
            author: authorFromModel(model.author),
            start_date: serialize.dateAsISOString(model.startDate),
            end_date: serialize.dateAsISOString(model.endDate),
            created_at: serialize.dateAsISOString(model.createdAt),
            updated_at: serialize.dateAsISOString(model.updatedAt),
            association_type: model.associationType,
            association_path: model.associationPath,
            association_hierarchy:
                model.associationHierarchy.map(hierarchyFromModel),
            parent_location_id: model.parentLocationId,
            parent_location: Location.createPayload(model.parentLocation),
            parent_hierarchy_id: model.parentHierarchyId,
            parent_hierarchy: hierarchyFromModel(model.parentHierarchy),
            is_visible: model.visible === true,
        };
    }

    /**
     * Convert from model.
     *
     * @param {Note.Model.Author} model
     * @returns {Note.Payload.Author}
     */
    static authorFromModel(model) {
        const { serialize } = this;
        return {
            id: model.id,
            email: model.email,
            user_name: model.username,
            first_name: model.firstName,
            last_name: model.lastName,
            created_at: serialize.dateAsISOString(model.createdAt),
            updated_at: serialize.dateAsISOString(model.updatedAt),
            number_of_notes: model.numberOfNotes ?? 0,
        };
    }

    /**
     * Convert from model.
     *
     * @param {Note.Model.Hierarchy} model
     * @returns {Note.Payload.Hierarchy}
     */
    static hierarchyFromModel(model) {
        const { serialize } = this;
        return {
            id: model.id,
            name: model.name,
            created_at: serialize.dateAsISOString(model.createdAt),
            updated_at: serialize.dateAsISOString(model.updatedAt),
            path: model.path,
            depth: model.depth ?? 0,
            parent_id: model.parentId,
        };
    }

    // -----------------------------------------------------
    // CONSTRUCTOR
    // -----------------------------------------------------

    /**
     * Create resource instance.
     *
     * @param {Partial<PayloadData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            PayloadAttributeFactory.create(
                NotePayload.attributesFromModel,
                NotePayload.defaults
            )
        );
    }

    /** Displays the specified tag when printing to the console. */
    get [Symbol.toStringTag]() {
        return 'Note\\Payload';
    }

    // -----------------------------------------------------
    // RESOURCE INTERFACE
    // -----------------------------------------------------

    /** Get shallow copy of this instance as a resource payload. */
    toPayload() {
        return this.clone();
    }

    /** Get shallow copy of this instance as a resource model. */
    toModel() {
        return NoteModel.fromPayload(this);
    }

    // -----------------------------------------------------
    // ATTRIBUTABLE INTERFACE
    // -----------------------------------------------------

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (NotePayload.create(this));
    }

    // -----------------------------------------------------
    // ATTRIBUTE::PROPERTIES
    // -----------------------------------------------------

    get id() {
        return this.get('id');
    }

    set id(value) {
        this.set('id', value);
    }

    get title() {
        return this.get('title');
    }

    set title(value) {
        this.set('title', value);
    }

    get content() {
        return this.get('content');
    }

    set content(value) {
        this.set('content', value);
    }

    get account_id() {
        return this.get('account_id');
    }

    set account_id(value) {
        this.set('account_id', value);
    }

    get account() {
        return this.get('account');
    }

    set account(value) {
        this.set('account', value);
    }

    get author_id() {
        return this.get('author_id');
    }

    set author_id(value) {
        this.set('author_id', value);
    }

    get start_date() {
        return this.get('start_date');
    }

    set start_date(value) {
        this.set('start_date', value);
    }

    get end_date() {
        return this.get('end_date');
    }

    set end_date(value) {
        this.set('end_date', value);
    }

    get created_at() {
        return this.get('created_at');
    }

    set created_at(value) {
        this.set('created_at', value);
    }

    get updated_at() {
        return this.get('updated_at');
    }

    set updated_at(value) {
        this.set('updated_at', value);
    }

    get association_type() {
        return this.get('association_type');
    }

    set association_type(value) {
        this.set('association_type', value);
    }

    get association_path() {
        return this.get('association_path');
    }

    set association_path(value) {
        this.set('association_path', value);
    }

    get association_hierarchy() {
        return this.get('association_hierarchy');
    }

    set association_hierarchy(value) {
        this.set('association_hierarchy', value);
    }

    get parent_location_id() {
        return this.get('parent_location_id');
    }

    set parent_location_id(value) {
        this.set('parent_location_id', value);
    }

    get parent_location() {
        return this.get('parent_location');
    }

    set parent_location(value) {
        this.set('parent_location', value);
    }

    get parent_hierarchy_id() {
        return this.get('parent_hierarchy_id');
    }

    set parent_hierarchy_id(value) {
        this.set('parent_hierarchy_id', value);
    }

    get parent_hierarchy() {
        return this.get('parent_hierarchy');
    }

    set parent_hierarchy(value) {
        this.set('parent_hierarchy', value);
    }

    get is_visible() {
        return this.get('is_visible');
    }

    set is_visible(value) {
        this.set('is_visible', value);
    }
}
