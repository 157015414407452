// <!-- INTERFACES -->
import { ModelResource } from '@/utils/resources';
import { ModelAttributeFactory } from '@/utils/resources';
import { NotePayload } from '@/payloads/v2/notes/NotePayload';

// <!-- ENUMS -->
import { AssociationType } from '@/enums';
import { Location } from '@/models/v2/locations';

/**
 * Create a specialized resource instance.
 *
 * @extends {ModelResource<PayloadData,ModelData>}
 * @implements {Resource.Model<PayloadData,ModelData>}
 * @implements {ModelData}
 */
export class NoteModel extends ModelResource {
    // -----------------------------------------------------
    // TYPE ALIASES
    // -----------------------------------------------------

    /** @typedef {Note.Payload} PayloadData */
    /** @typedef {Note.Model} ModelData */

    // -----------------------------------------------------
    // STATIC FACTORIES
    // -----------------------------------------------------

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} attributes
     * @returns {NoteModel}
     */
    static create(attributes) {
        return new NoteModel(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} data
     * @returns {NoteModel}
     */
    static fromPayload(data) {
        const attributes = NoteModel.attributesFromPayload(data);
        return NoteModel.create(attributes);
    }

    /**
     * Create an index resource.
     * @param {Partial<PayloadData>} payload
     * @returns {Note.Model.Index}
     */
    static indexFromPayload(payload) {
        const { parse } = ModelResource;
        return {
            id: payload.id,
            title: payload.title,
            content: payload.content,
            accountId: payload.account_id,
            authorId: payload.author_id,
            startDate: parse.dateString(payload.start_date),
            endDate: parse.dateString(payload.end_date),
            createdAt: parse.dateString(payload.created_at),
            updatedAt: parse.dateString(payload.updated_at),
            associationType: payload.association_type,
            associationPath: payload.association_path,
            parentLocationId: payload.parent_location_id,
            parentHierarchyId: payload.parent_hierarchy_id,
            visible: payload.is_visible === true,
        };
    }

    // -----------------------------------------------------
    // STATIC PROPERTIES
    // -----------------------------------------------------

    /** @type {Readonly<ModelData>} */
    static get defaults() {
        return {
            id: undefined,
            title: undefined,
            content: undefined,
            accountId: undefined,
            account: undefined,
            authorId: undefined,
            author: undefined,
            startDate: undefined,
            endDate: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            associationType: AssociationType.None,
            associationPath: '%%%%',
            associationHierarchy: [],
            parentLocationId: null,
            parentLocation: null,
            parentHierarchyId: null,
            parentHierarchy: null,
            visible: true,
        };
    }

    // -----------------------------------------------------
    // STATIC UTILITIES
    // -----------------------------------------------------

    /**
     * Create resource attributes from the payload data.
     *
     * @param {Partial<PayloadData>} payload
     * @returns {Partial<ModelData>}
     */
    static attributesFromPayload(payload) {
        // Get transformer functions.
        const { parse, authorFromPayload, hierarchyFromPayload } = this;

        // Return the created state.
        return {
            id: payload.id,
            title: payload.title,
            content: payload.content,
            accountId: payload.account_id,
            account: payload.account,
            authorId: payload.author_id,
            author:
                payload.author != null
                    ? authorFromPayload(payload.author)
                    : null,
            startDate: parse.dateString(payload.start_date),
            endDate: parse.dateString(payload.end_date),
            createdAt: parse.dateString(payload.created_at),
            updatedAt: parse.dateString(payload.updated_at),
            associationType: payload.association_type,
            associationPath: payload.association_path,
            associationHierarchy:
                payload.association_hierarchy?.map(hierarchyFromPayload),
            parentLocationId: payload.parent_location_id,
            parentLocation:
                payload.parent_location != null
                    ? Location.create.fromPayload(payload.parent_location)
                    : null,
            parentHierarchyId: payload.parent_hierarchy_id,
            parentHierarchy:
                payload.parent_hierarchy != null
                    ? hierarchyFromPayload(payload.parent_hierarchy)
                    : null,
            visible: payload.is_visible === true,
        };
    }

    /**
     * Convert from payload.
     *
     * @param {Note.Payload.Author} payload
     * @returns {Note.Model.Author}
     */
    static authorFromPayload(payload) {
        const { parse } = ModelResource;
        return {
            id: payload?.id,
            email: payload?.email,
            username: payload?.user_name,
            firstName: payload?.first_name,
            lastName: payload?.last_name,
            createdAt: parse.dateString(payload?.created_at),
            updatedAt: parse.dateString(payload?.updated_at),
            numberOfNotes: payload?.number_of_notes ?? 0,
        };
    }

    /**
     * Convert from model.
     *
     * @param {Note.Payload.Hierarchy} payload
     * @returns {Note.Model.Hierarchy}
     */
    static hierarchyFromPayload(payload) {
        const { parse } = ModelResource;
        return {
            id: payload.id,
            name: payload.name,
            createdAt: parse.dateString(payload.created_at),
            updatedAt: parse.dateString(payload.updated_at),
            path: payload.path,
            depth: payload.depth ?? 0,
            parentId: payload.parent_id,
        };
    }

    // -----------------------------------------------------
    // CONSTRUCTOR
    // -----------------------------------------------------

    /**
     * Create resource instance.
     *
     * @param {Partial<ModelData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            ModelAttributeFactory.create(
                NoteModel.attributesFromPayload,
                NoteModel.defaults
            )
        );
    }

    /** Displays the specified tag when printing to the console. */
    get [Symbol.toStringTag]() {
        return 'Note\\Model';
    }

    // -----------------------------------------------------
    // RESOURCE INTERFACE
    // -----------------------------------------------------

    /** Get shallow copy of this instance as a resource payload. */
    toPayload() {
        return NotePayload.fromModel(this);
    }

    /** Get shallow copy of this instance as a resource model. */
    toModel() {
        return this.clone();
    }

    // -----------------------------------------------------
    // ATTRIBUTABLE INTERFACE
    // -----------------------------------------------------

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (NoteModel.create(this));
    }

    // -----------------------------------------------------
    // ATTRIBUTE::PROPERTIES
    // -----------------------------------------------------

    get id() {
        return this.get('id');
    }

    set id(value) {
        this.set('id', value);
    }

    get title() {
        return this.get('title');
    }

    set title(value) {
        this.set('title', value);
    }

    get content() {
        return this.get('content');
    }

    set content(value) {
        this.set('content', value);
    }

    get accountId() {
        return this.get('accountId');
    }

    set accountId(value) {
        this.set('accountId', value);
    }

    get account() {
        return this.get('account');
    }

    set account(value) {
        this.set('account', value);
    }

    get authorId() {
        return this.get('authorId');
    }

    set authorId(value) {
        this.set('authorId', value);
    }

    get startDate() {
        return this.get('startDate');
    }

    set startDate(value) {
        this.set('startDate', value);
    }

    get endDate() {
        return this.get('endDate');
    }

    set endDate(value) {
        this.set('endDate', value);
    }

    get createdAt() {
        return this.get('createdAt');
    }

    set createdAt(value) {
        this.set('createdAt', value);
    }

    get updatedAt() {
        return this.get('updatedAt');
    }

    set updatedAt(value) {
        this.set('updatedAt', value);
    }

    get associationType() {
        return this.get('associationType');
    }

    set associationType(value) {
        this.set('associationType', value);
    }

    get associationPath() {
        return this.get('associationPath');
    }

    set associationPath(value) {
        this.set('associationPath', value);
    }

    get associationHierarchy() {
        return this.get('associationHierarchy');
    }

    set associationHierarchy(value) {
        this.set('associationHierarchy', value);
    }

    get parentLocationId() {
        return this.get('parentLocationId');
    }

    set parentLocationId(value) {
        this.set('parentLocationId', value);
    }

    get parentLocation() {
        return this.get('parentLocation');
    }

    set parentLocation(value) {
        this.set('parentLocation', value);
    }

    get parentHierarchyId() {
        return this.get('parentHierarchyId');
    }

    set parentHierarchyId(value) {
        this.set('parentHierarchyId', value);
    }

    get parentHierarchy() {
        return this.get('parentHierarchy');
    }

    set parentHierarchy(value) {
        this.set('parentHierarchy', value);
    }

    get visible() {
        return this.get('visible');
    }

    set visible(value) {
        this.set('visible', value);
    }
}
