// <!-- API -->
import { computed } from 'vue';
import { createEventHook } from '@vueuse/shared';

// <!-- COMPOSABLES -->
import { useModals } from '@/hooks/modals';

// <!-- CLASS -->
/**
 * Define a Note modals managing class.
 */
class Modals {
    /**
     * Instantiate instance.
     */
    constructor() {
        // Events.
        /** @type {Vue.EventHook<{ id: 'add' | 'edit' | 'view' | 'delete', event?: MouseEvent }>} */
        const clicked = createEventHook();

        // Event Triggers.
        this.click = clicked.trigger;

        // Event Listeners.
        this.onClicked = clicked.on;

        // Modals.
        const {
            enabled,
            disabled,
            isOpen,
            isClosed,
            reveal,
            close,
            onRevealed,
            onClosed,
        } = useModals(['add', 'edit', 'delete']);

        // Reactivity.
        this.enabled = enabled;
        this.disabled = disabled;
        // this.targets = {
        //     /** @type {Vue.Ref<Note.Form.AddData>} */
        //     add: ref(null),
        //     /** @type {Vue.Ref<Note.Form.EditData>} */
        //     edit: ref(null),
        //     /** @type {Vue.Ref<Note.Form.DeleteData>} */
        //     delete: ref(null),
        // };

        // Conditionals.
        this.isAddModalOpen = computed(() => enabled.value.has('add'));
        this.isEditModalOpen = computed(() => enabled.value.has('edit'));
        this.isDeleteModalOpen = computed(() => enabled.value.has('delete'));

        // Methods
        this.reveal = reveal;
        this.close = close;
        this.isOpen = isOpen;
        this.isClosed = isClosed;
        this.onRevealed = onRevealed;
        this.onClosed = onClosed;

        /**
         * Trigger button clicked.
         * @param {MouseEvent} event
         */
        this.onAddButtonClicked = (event) => {
            const id = 'add';
            this.click({ id, event });
        };

        /**
         * Trigger button clicked.
         * @param {MouseEvent} event
         */
        this.onEditButtonClicked = (event) => {
            const id = 'edit';
            this.click({ id, event });
        };

        /**
         * Trigger button clicked.
         * @param {MouseEvent} event
         */
        this.onViewButtonClicked = (event) => {
            const id = 'view';
            this.click({ id, event });
        };

        /**
         * Trigger button clicked.
         * @param {MouseEvent} event
         */
        this.onDeleteButtonClicked = (event) => {
            const id = 'delete';
            this.click({ id, event });
        };

        // Lifecycle

        this.onClicked(({ id }) => {
            const key = id === 'view' ? 'edit' : id;
            if (this.isClosed(key)) {
                this.reveal(key);
            }
        });
    }
}
// <!-- COMPOSABLE -->

/**
 * Prepare note modal controller.
 */
export const useNoteModals = () => {
    return new Modals();
};

// <!-- DEFAULT -->
export default useNoteModals;
