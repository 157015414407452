// <!-- API -->
import { h } from 'vue';

// <!-- TYPES -->
/** @typedef {{ span: String[] }} IHorizontalBarProps */

/**
 * @type {V.FunctionalComponent<IHorizontalBarProps>}
 * Render function for an HTML horizontal bar to the DOM.
 */
export const HorizontalRule = (props) => {
    // Get the horizontal span.
    const span = props.span ?? ['col-span-12'];
    // Return the VNode for the rendered comment.
    return h(`hr`, { class: span });
};

// Register the props for the component.
HorizontalRule.displayName = 'Horizontal Rule';
HorizontalRule.props = ['span'];

// <!-- DEFAULT -->
export default HorizontalRule;
