<template>
    <div
        v-if="$slots.default"
        :class="
            grid ?? [
                'mt-6',
                'grid grid-cols-1 gap-y-6 gap-x-0',
                'sm:grid-cols-3',
            ]
        "
    >
        <!-- FORM SECTION CONTENT -->
        <slot></slot>
    </div>
</template>

<script>
    /**
     * If slots are not used, fallback content will rely on props.
     */

    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FormSectionContent',
        props: {
            /** Array of grid classes. */
            grid: {
                /** @type {V.PropType<String[]>} */
                type: Array,
                default: () => null,
            },
        },
    });
</script>
