// <!-- API -->
import { ref, computed } from 'vue';
import { useECNBCache, ECNBCache } from '@/hooks/store/useECNBCache';

// <!-- TYPES -->

import { NoteResource } from '@/models/v1/notes/Note';

/**
 * Using a cache (or with a new instance), handle index operations.
 * @param {ECNBCache} [vuexCache]
 */
export const useNoteIndex = (vuexCache) => {
    // STORE

    /** @type {ECNBCache} */
    const cache = vuexCache ?? useECNBCache();

    // STATE

    /** @type {V.Ref<Boolean>} */
    const isFetching = ref(false);

    // COMPUTED PROPERTIES

    /** @type {V.ComputedRef<NoteResource[]>} */
    const notes = computed(() => {
        const dependencies = [
            // When this changes, we should refresh our cache list.
            isFetching.value,
        ];
        const notesCache = cache.notes.value;
        const isEmpty = notesCache.is.empty;
        return isEmpty ? [] : notesCache.all.all();
    });

    // METHODS

    /**
     * Refetch the cached index for the page.
     * @param {Boolean} forceReload
     */
    const refreshNoteIndex = async (forceReload = false) => {
        try {
            isFetching.value = true;
            const ignoreCache = forceReload === true;
            await cache.fetch.notes({ ignoreCache });
            return notes.value;
        } catch (err) {
            console.error(err);
        } finally {
            isFetching.value = false;
        }
    };

    return {
        refreshNoteIndex,
        isFetching,
        cache,
        notes,
    };
};
