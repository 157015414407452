<template>
    <div class="sm:hidden block sm:pt-0">
        <PageHeader>{{ currentRouteName }}</PageHeader>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed } from 'vue';
    import { useRoute } from 'vue-router';

    // <!-- COMPONENTS -->
    import PageHeader from '@components/PageHeader.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'PageTitle',
        components: {
            PageHeader,
        },
        setup() {
            // ==== COMPOSE ====
            const route = useRoute();

            // ==== COMPUTED ====
            /** @type {V.ComputedRef<Router.RouteRecordName>} */
            const currentRouteName = computed(() => route.name);

            // ==== EXPOSE ====
            return {
                currentRouteName,
            };
        },
    });
</script>
