// <!-- REACTIVITY API -->
import { ref } from 'vue';
import { useRouter } from 'vue-router';

/**
 * Create appropriate state and hooks for using tabs.
 * @param {{ tabs: Tab[] | Vue.Ref<Tab[]>}} state Local state to use.
 * @param {Router.Instance} router Custom property hook.
 */
export const useTabDefs = (state, router = useRouter()) => {
    // Create reactive reference.
    const tabs = ref(state.tabs);

    /**
     * Check if this is the current tab.
     * @param {Tab} tab Tab to check.
     */
    const isCurrentTab = (tab) =>
        tab.to === router.currentRoute.value.path ||
        tab.to === router.currentRoute.value.name;

    // Prepare callback hook.
    const updateCurrentTabByRoute = () => {
        tabs.value.forEach((tab) => {
            tab.current = isCurrentTab(tab);
            return tab;
        });
    };

    // Expose reactive state and hooks.
    return {
        tabs,
        isCurrentTab,
        updateCurrentTabByRoute,
    };
};
