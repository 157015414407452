// <!-- API -->
import { createEventHook } from '@vueuse/shared';

// <!-- COMPOSABLES -->
import { useForm } from '@/hooks/forms';

// <!-- ENUMS -->
import { AssociationType } from '@/enums';
import { NoteModel } from '@/models/v2/notes/NoteModel';

// <!-- CLASS -->
/**
 * Define a Note modals managing class.
 */
class Forms {
    /**
     * Instantiate instance.
     */
    constructor() {
        // Default form targets.
        this.template = {
            /** @type {Note.Model} */
            add: NoteModel.create({
                title: null,
                content: null,
                authorId: null,
                author: null,
                associationType: AssociationType.None,
                associationHierarchy: [],
                parentLocationId: null,
                parentLocation: null,
                parentHierarchyId: null,
                parentHierarchy: null,
            }),
            /** @type {Note.Model} */
            edit: NoteModel.create({
                id: null,
                title: null,
                content: null,
                authorId: null,
                author: null,
                associationType: AssociationType.None,
                associationHierarchy: [],
                parentLocationId: null,
                parentLocation: null,
                parentHierarchyId: null,
                parentHierarchy: null,
            }),
            /** @type {Note.Model} */
            delete: NoteModel.create({
                id: null,
            }),
        };

        // Services.
        this.state = {
            /** @type {Form.UseFormReturn<'add', Omit<Note.Form.AddData, 'form'>>} */
            add: useForm({ form: 'add' }),
            /** @type {Form.UseFormReturn<'edit', Omit<Note.Form.EditData, 'form'>>} */
            edit: useForm({ form: 'edit' }),
            /** @type {Form.UseFormReturn<'delete', Omit<Note.Form.DeleteData, 'form'>>} */
            delete: useForm({ form: 'delete' }),
        };
    }
}

// <!-- COMPOSABLE -->

/**
 * Prepare note modal controller.
 */
export const useNoteForms = () => {
    return new Forms();
};

// <!-- DEFAULT -->
export default useNoteForms;
