// <!-- API -->
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useNow, useAsyncState } from '@vueuse/core';
import profile from '@/api/v2/profile';
import organizations from '@/api/v2/organizations';

// <!-- COMPOSABLE -->
/**
 * Define composable that can optionally accept an already defined store.
 * @param {Object} [props]
 * @param {import('vuex').Store<import('@/store/types/ECNBStore').ECNBState>} [props.store]
 */
export const useExpiredSubscriptionGate = (props = {}) => {
    // PROPS
    const { store = useStore() } = props;

    // STATE
    const now = useNow();
    const user = computed(() => store.state?.users?.me);
    const organization = computed(() => store.state?.accounts?.organization);
    const isSuperUser = computed(() => user.value?.isSuperUser === true);
    const role = computed(() => {
        return isSuperUser.value === true
            ? 'admin'
            : organization.value?.access?.userRole;
    });

    // const expirationDate = computed(() => {
    //     const _expireAt = organization.value?.currentSubscription?.expireAt;
    //     return _expireAt;
    // });

    const subscriptions = useAsyncState(
        async () => {
            if (!organization.value) {
                // No subscriptions, if no organization is selected.
                return /** @type {Subscription.Model[]} */ ([]);
            }

            // Fetch the subscriptions.
            const response = await organizations.fetchOrganizationSubscriptions(
                organization.value
            );

            // Return the subscriptions.
            const subscriptions = response.mapOr([], (m) => m);
            return subscriptions;
        },
        /** @type {Subscription.Model[]} */ ([]),
        {
            throwError: true,
            immediate: true,
        }
    );

    const expirationDate = computed(() => {
        // Compute the expiration date from the current one.
        if (!!organization.value?.currentSubscription) {
            const _subscription = organization.value?.currentSubscription;
            return _subscription?.expireAt;
        }

        // Compute from the latest subscription in the history.
        const all = subscriptions.state.value;
        const paid = all.filter((m) => m.paidAt);
        const sortedByUpdatedAt = paid.sort((l, r) => {
            const _l = l.updatedAt?.valueOf() ?? 0;
            const _r = r.updatedAt?.valueOf() ?? 0;
            return _l - _r;
        });
        const latest = sortedByUpdatedAt.at(-1);
        return latest?.expireAt;
    });

    const isCurrentSubscriptionExpired = computed(() => {
        const _subscription = organization.value?.currentSubscription;
        const _missing = _subscription == null;
        const _now = now.value.valueOf();
        const _expireAt = _subscription?.expireAt?.valueOf() ?? Infinity;
        return _missing || _now >= _expireAt;
    });

    const isCurrentSubscriptionActive = computed(() => {
        return isCurrentSubscriptionExpired.value !== true;
    });

    const isCurrentSubscriptionMissing = computed(() => {
        const _subscription = organization.value?.currentSubscription;
        const _missing = _subscription == null;
        return _missing;
    });

    const isOrganizationMember = computed(
        () =>
            isSuperUser.value ||
            ['admin', 'data-manager', 'data-analyst'].includes(role.value)
    );

    const isOrganizationAdministrator = computed(
        () =>
            isSuperUser.value ||
            (isOrganizationMember.value && role.value === 'admin')
    );

    /**
     * Fetch the current profile user.
     */
    const fetchCurrentUser = async () => {
        return await store.dispatch('fetchCurrentUser');
    };

    /**
     * Fetch the current user's organizations.
     */
    const fetchUserOrganizations = async () => {
        if (isSuperUser.value) {
            // Super users.
            const response = await organizations.fetchOrganizations();
            const index = response.mapOr([], (arr) => arr);
            return index;
        }

        // Normal users.
        const response = await profile.fetchUserOrganizations();
        const index = response.mapOr([], (arr) => arr);
        return index;
    };

    /**
     * Fetch the selected organization, based on session storage.
     */
    const fetchSelectedOrganization = async () => {
        // Load if organization details are missing.
        const organizations = await fetchUserOrganizations();

        // Return null if empty.
        if (organizations.length <= 0) {
            return null;
        }

        // Get the selected organization.
        const id = Number.parseInt(
            sessionStorage.getItem('selected_organization')
        );

        // If the id is defined, load that organization. Otherwise get the first one.
        const isValidIdentifier = !Number.isNaN(id);

        // Use the primary organization check.
        const usePrimaryOrganization = !isValidIdentifier;

        // Determine the selected index.
        const index = usePrimaryOrganization
            ? 0
            : organizations.findIndex((o) => o.id === id);

        // Select the organization.
        const selected = organizations[index];

        // If using primary organization, select it.
        return await selectOrganization(selected);
    };

    /**
     * @param {Pick<Organization.Model, 'id'>} target
     */
    const selectOrganization = async (target) => {
        // Select an organization.
        const response = await organizations.fetchOrganizationById(target);
        const selected = response.mapOr(null, (o) => o);

        // Save it to the store.
        store.commit('setCurrentOrganization', selected);

        // Return the selection.
        return selected;
    };

    // EXPOSE
    return {
        user,
        role,
        organization,
        subscriptions,
        expirationDate,
        fetchCurrentUser,
        fetchUserOrganizations,
        fetchSelectedOrganization,
        selectOrganization,
        isSuperUser,
        isCurrentSubscriptionActive,
        isCurrentSubscriptionExpired,
        isCurrentSubscriptionMissing,
        isOrganizationMember,
        isOrganizationAdministrator,
    };
};

// <!-- DEFAULT -->
export default useExpiredSubscriptionGate;
