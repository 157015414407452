// <!-- API -->
import { ref, computed } from 'vue';
import { createEventHook, resolveUnref } from '@vueuse/shared';

// <!-- UTILITIES -->
import clone from 'just-clone';

// <!-- CLASS -->

/**
 * Prepares the form.
 * @template {string} [TForm=string]
 * @template {Record<string,any>} [TData=Record<string,any>]
 * @implements {Form.UseFormReturn<TForm, TData>}
 */
class FormContext {
    /**
     * Instantiate a form instance.
     * @param {Form.UseFormOptions<TForm, TData>} props
     */
    constructor(props) {
        // PROPS
        const { form, initialState = null } = props;

        // EVENTS
        /** @type {Vue.EventHook<{ initialState: TData }>} */
        const initialized = createEventHook();
        /** @type {Vue.EventHook<{ reason?: unknown, formData?: TData }>} */
        const cancelled = createEventHook();
        /** @type {Vue.EventHook<{ formData: TData }>} */
        const submitted = createEventHook();

        // EVENT TRIGGERS
        this.reset = initialized.trigger;
        this.cancel = cancelled.trigger;
        this.submit = submitted.trigger;

        // EVENT LISTENERS
        this.onReset = initialized.on;
        this.onCancel = cancelled.on;
        this.onSubmit = submitted.on;

        // CONSTANTS
        this.form = Object.freeze(form);

        // REACTIVITY
        /** @type {Vue.Ref<TData>} */
        this.target = ref(null);
        const editing = ref(false);

        // CONDITIONALS
        this.isEditing = computed(() => editing.value === true);

        // METHODS
        /**
         * Clear the target.
         */
        this.clear = () => {
            this.target.value = null;
        };

        /**
         * Set the target.
         * @param {TData} data
         */
        this.populate = (data) => {
            this.target.value = data;
        };

        /**
         * Enable editing mode.
         */
        this.editing = () => (editing.value = true);

        /**
         * Disable editing mode.
         */
        this.readonly = () => (editing.value = false);

        /**
         * Toggle the editing state.
         */
        this.toggleEditing = () => {
            editing.value = !editing.value;
        };

        // LIFECYCLE

        // onInit...
        const data = resolveUnref(initialState);
        this.target.value = data;

        // Handle reset.
        this.onReset((event) => {
            console.log(`[${this.form}::init]`);
            this.clear();
            this.populate(clone(event.initialState));
        });

        // Handle cancellation.
        this.onCancel((event) => {
            console.log(`[${this.form}::cancel]`);
            // console.warn(event.reason);
            // console.dir(event.formData);
        });

        // Handle submission.
        this.onSubmit((event) => {
            console.log(`[${this.form}::submit]`);
            // console.dir(event.formData);
        });
    }
}

// <!-- COMPOSABLE -->

/**
 * Instantiate a composable form state.
 * @template {string} [TForm=string]
 * @template {Record<string,any>} [TData=Record<string,any>]
 * @param {Form.UseFormOptions<TForm, TData>} props
 * @returns {Form.UseFormReturn<TForm, TData>}
 */
export const useForm = (props) => {
    return new FormContext(props);
};

// <!-- DEFAULT -->
export default useForm;
