<template>
    <h3
        v-if="$slots.default || title?.length > 0"
        class="text-lg leading-6 font-medium text-gray-900"
    >
        <slot>
            <!-- `props.title` HIDDEN WHEN USING `$slots.default` -->
            <span class="inline">
                {{ title }}
            </span>
        </slot>
    </h3>
</template>

<script>
    /**
     * If slots are not used, fallback content will rely on props.
     */

    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FormSectionTitle',
        props: {
            /** Form section title. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
        },
    });
</script>
