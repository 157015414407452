<template>
    <div
        class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
    >
        <div
            v-if="$slots.header"
            class="px-4 py-5 sm:px-6"
        >
            <slot name="header"></slot>
            <!-- We use less vertical padding on card headers on desktop than on body sections -->
        </div>
        <div
            v-if="$slots.default"
            class="px-4 py-5 sm:p-6"
        >
            <slot name="default"></slot>
        </div>
        <div
            v-if="$slots.footer"
            class="px-4 py-4 sm:px-6"
        >
            <slot name="footer"></slot>
            <!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Panel',
    });
</script>
