<template>
    <div class="main-section pt-2">
        <div class="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 pb-10">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'PageWrapper',
    });
</script>

<style scoped>
    .main-section {
        min-height: calc(100vh - 4rem);
    }

    @media print {
        .main-section {
            height: auto;
        }
    }
</style>
