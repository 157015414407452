// <!-- UTILITIES -->
import { ref, computed } from 'vue';
import { resolveUnref } from '@vueuse/core';

// <!-- TYPES -->
/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef UseAgGridRowDataOptions
 * @prop {TData[] | V.Ref<TData[]>} [data] Possibly reactive initial data.
 */

/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef {UseAgGridRowDataState<TData>
 *          & UseAgGridRowDataProperties<TData>
 *          & UseAgGridRowDataCommands<TData>
 *          & UseAgGridRowDataQueries<TData>
 * } UseAgGridRowDataReturn
 */

/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef UseAgGridRowDataState
 * @prop {V.Ref<TData[]>} rowData Reactive row data property.
 */

/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef UseAgGridRowDataProperties
 * @prop {V.ComputedRef<number>} size Size of the rowData collection.
 * @prop {V.ComputedRef<boolean>} isEmpty Indicates emptiness of the rowData collection.
 */

/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef UseAgGridRowDataCommands
 * @prop {(data: TData[]) => void} set Set the row data value.
 * @prop {() => void} clear Clear the row data.
 */

/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef UseAgGridRowDataQueries
 * @prop {(compareFn: (a: TData, b: TData) => number) => TData[]} sort Get sorted rowData collection.
 * @prop {<U>(mapperFn: (value: TData, index: number, array: TData[]) => U, thisArg?: any) => U[]} map Map row data items.
 */

/**
 * Define common row data properties.
 * @template [TData=any]
 * @param {UseAgGridRowDataOptions<TData>} [props]
 * @returns {UseAgGridRowDataReturn<TData>}
 */
export const useAgGridRowData = (props = {}) => {
    /** @type {TData[]} Initial data collection. */
    const initialData = resolveUnref(props.data ?? []);
    const rowData = /** @type {V.Ref<TData[]>} */ (ref([...initialData]));
    const size = computed(() => rowData.value.length);
    const isEmpty = computed(() => size.value === 0);
    return {
        rowData,
        size,
        isEmpty,
        set: (data) => (rowData.value = [...data]),
        clear: () => (rowData.value = []),
        sort: (compareFn) => rowData.value.sort(compareFn),
        get map() {
            return rowData.value.map;
        },
    };
};

// <!-- DEFAULT -->
export default useAgGridRowData;
