import { Location } from '@/models/v2/locations';

/**
 * Resource data transfer object.
 * @implements {globalThis.Location.Payload}
 */
export class LocationPayload {
    /** Get the static factory methods. */
    static get create() {
        return {
            /** @type {globalThis.PayloadConstructor<globalThis.Location.Payload>} */
            fromData: (data) => new LocationPayload(data),
        };
    }

    /**
     * Construct a payload instance.
     * @param {Partial<globalThis.Location.Payload>} data
     */
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.serial = data.serial;
        this.min_date = data.min_date;
        this.max_date = data.max_date;
        this.data_logger_manufacturer = data.data_logger_manufacturer;
        this.data_logger_serial_number = data.data_logger_serial_number;
        this.data_logger_placement = data.data_logger_placement;
        this.collections_contact = data.collections_contact;
        this.facilities_contact = data.facilities_contact;
        this.department_or_division = data.department_or_division;
        this.air_source = data.air_source;
        this.bms_id = data.bms_id;
        this.zone = data.zone;
        this.photo = data.photo;
        this.floorplan = data.floorplan;
        this.timezone = data.timezone;
        this.location_hierarchy_id = data.location_hierarchy_id;
        this.hierarchy = data.hierarchy;
        this.nara_standard = data.nara_standard;
    }

    // SERVICE METHODS

    /**
     * Convert the paylod into a model.
     * @returns {globalThis.Location.Model}
     */
    toModel() {
        return Location.create.fromPayload(this);
    }
}
