// <!-- API -->
import { ref, computed } from 'vue';
import { createEventHook, resolveUnref } from '@vueuse/shared';

// <!-- COMPOSABLES -->
import {
    useAgGridPagination,
    useColumnResizedEvent,
    useGridReadyEvent,
    useRowDataChangedEvent,
} from '@/hooks/grid';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import { Enum } from '@/utils/enums';
import { formatISO } from 'date-fns';
import { DateTimeISO } from '@/utils/datetime';
import { useCaseInsensitiveLocaleCompare } from '@/utils/sorters';

// <!-- COMPONENTS -->
import NoteManagerTableIcons from '../components/cells/NoteManagerTableIcons.vue';
import { AssociationType } from '@/enums';

// <!-- CLASS -->
/**
 * Define a Note grid managing class.
 */
class Grid {
    /**
     * Get the column fields.
     */
    static getColumnFields() {
        return Enum.create({
            id: 'id',
            title: 'title',
            content: 'content',
            author: 'author',
            startDate: 'startDate',
            endDate: 'endDate',
            associationPath: 'associationPath',
        });
    }

    /**
     * @returns {AgGrid.ColumnDef<Note.Row>}
     */
    static getDefaultColumnDefinition() {
        return {
            cellClass: 'leading-5 py-2 break-normal',
            filter: true,
            flex: 1,
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            headerName: '',
            lockPosition: true,
            minWidth: 150,
            resizable: true,
            sortable: true,
            suppressMenu: true,
            suppressMovable: true,
        };
    }

    /**
     * Get the pagination state.
     * @param {import('@/hooks/grid/useAgGridPagination').UseAgGridPaginationOptions} [props]
     */
    static usePagination(props) {
        const { pagination: enabled, paginationPageSize: size } =
            useAgGridPagination(props);
        return { enabled, size };
    }

    /**
     * Instantiate instance.
     * @param {Vue.Ref<Note.Row[]> | Note.Row[]} [initialState]
     */
    constructor(initialState = []) {
        // Props.
        const initialData = resolveUnref(initialState);

        // Events.
        /** @type {import('@/hooks/grid/events/useGridReadyEvent').UseGridReadyEventReturn<Note.Row>} */
        const { onGridReady, readyGrid } = useGridReadyEvent();

        /** @type {import('@/hooks/grid/events/useColumnResizedEvent').UseColumnResizedEventReturn<Note.Row>} */
        const { onColumnResized, resizeColumn } = useColumnResizedEvent();

        /** @type {import('@/hooks/grid/events/useRowDataChangedEvent').UseRowDataChangedEventReturn<Note.Row>} */
        const { onRowDataChanged, changeRowData } = useRowDataChangedEvent();

        /** @type {Vue.EventHook<Note.Row>} */
        const selected = createEventHook();

        /** @type {Vue.EventHook<{ target: Note.Row, action: 'add' | 'view' | 'edit' | 'delete' }>} */
        const clicked = createEventHook();

        // Event Triggers.
        this.handleGridReady = readyGrid;
        this.handleColumnResized = resizeColumn;
        this.updateRowData = changeRowData;
        const handleButtonClick = clicked.trigger;
        const selectRow = selected.trigger;

        // Event Listeners.
        const onNoteSelected = selected.on;
        this.onButtonClicked = clicked.on;

        // Services.
        /** @type {AgGrid.GridApi<Note.Row>} */
        this.gridApi = null;
        /** @type {AgGrid.ColumnApi} */
        this.gridColumnApi = null;
        /** @type {AgGrid.ColumnDef} */
        this.defaultColDef = Grid.getDefaultColumnDefinition();
        /** @type {string} */
        this.emptyMessage = 'No notes to display.';
        /** Pagination. */
        this.pagination = Grid.usePagination({ enabled: true, size: 25 });

        // Reactivity.
        /** @type {Vue.Ref<UserRole>} */
        this.role = ref('data-analyst');
        /** @type {Vue.Ref<string>} */
        this.displayTimezone = ref('UTC');
        /** @type {Vue.Ref<string[]>} */
        this.labels = ref(['Site', 'Building', 'Floor', 'Room']);
        /** @type {Vue.Ref<Note.Row[]>} */
        this.rowData = ref([...initialData]);
        /** @type {Vue.Ref<Note.Row>} */
        this.selectedRow = ref(null);

        // Conditionals.
        /** @type {Vue.ComputedRef<boolean>} */
        this.isEmpty = computed(() => this.rowData.value.length === 0);
        /** @type {Vue.ComputedRef<AgGrid.ColumnDef>} */
        this.columnDefs = computed(() => {
            const _ = this.role.value;
            return getColumnDefs();
        });

        // Methods.

        /** @type {(role: UserRole) => any} */
        this.setUserRole = (role) => (this.role.value = role);

        /** @type {(labels: string[]) => any} */
        this.setHierarchyLabels = (labels) => (this.labels.value = labels);

        /**
         * Format the ISO string into just its date component.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const useDateComponentFormat = (params) => {
            const value = params.value;
            if (!is.nullOrUndefined(value) && value !== '') {
                const date = DateTimeISO.parse(value);
                const formatted = formatISO(date, {
                    format: 'extended',
                    representation: 'date',
                });
                return formatted;
            }
            return 'No Date Provided';
        };

        /**
         * Format the ISO string into just its local date component.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const useDateLocalComponentFormat = (params) => {
            const value = params.value;
            if (!is.nullOrUndefined(value) && value !== '') {
                const date = DateTimeISO.parse(value);
                const formatter = new Intl.DateTimeFormat('en-ca', {
                    dateStyle: 'short',
                    timeZone: this.displayTimezone.value,
                });
                const formatted = formatter.format(date);
                return formatted;
            }
            return 'No Date Provided';
        };

        /**
         * Use locale comparator for strings.
         * @type {AgGrid.ColumnDef['comparator']}
         */
        const useLocaleComparator = useCaseInsensitiveLocaleCompare();

        /**
         * Get the account hierarchy labels.
         */
        const getAccountHierarchyLabels = () => {
            const defaultLabels = ['Site', 'Building', 'Floor', 'Room'];
            const accountLabels = this.labels.value ?? null;
            // $api.context.store.state.accounts?.account?.treeLabels ?? null;
            return accountLabels ?? defaultLabels;
        };

        /**
         * Get the note hierarchy path.
         * @type {AgGrid.ValueGetterFunc}
         */
        const usePathGetter = (params) => {
            /** @type {Note.Row} */
            const note = params?.data;

            // Get the hierarchy tree labels.
            const labels = [...getAccountHierarchyLabels()];

            /** Handle the path creation based on type. */
            switch (note.associationType) {
                case AssociationType.Location:
                    return note.associationPath;
                case AssociationType.None:
                    return `All ${labels[0]}s`;
            }

            // CASE: Determine the hierarchy path.
            const path = note.associationPath;
            // eg. "A/B/C/%" or "%%%%"

            // Count the number of wildcards.
            const numberOfWildcards = (path.match(/%/g) || []).length;

            // If the wildcard count is zero, just return the path.
            if (numberOfWildcards <= 0) {
                return path;
            }

            // Otherwise remove wildcards from the path string.
            const modified = path.replaceAll('%', '/');

            // Then split to get the valid terms.
            const hierarchy = modified.split('/');

            // Map labels into their appropriate path segment.
            const segments = hierarchy.reduce((array, next, index) => {
                if (index >= labels.length) {
                    // Return current array, if max size is found.
                    return array;
                }

                if (next === null || next === '') {
                    const label = labels[index];
                    return [...array, `All ${label}s`];
                }

                return [...array, String(next)];
            }, []);

            // Create the new path.
            return !!segments && segments.length > 0
                ? segments.join('/')
                : null;
        };

        /** @returns {Readonly<Partial<Record<keyof fields, { field: fields[keyof fields] } & AgGrid.ColumnDef>>>} */
        const getColumnSchema = () => {
            const isDataAnalyst = this.role.value === 'data-analyst';
            const fields = Grid.getColumnFields();
            return {
                id: {
                    headerName: '',
                    field: fields.id,
                    cellRenderer: NoteManagerTableIcons,
                    lockPosition: true,
                    filter: false,
                    minWidth: isDataAnalyst ? 50 : 115,
                    maxWidth: isDataAnalyst ? 75 : 145,
                    cellRendererParams: {
                        /**
                         * Handle grid action.
                         * @param {object} _
                         * @param {number} index
                         */
                        handleView: (_, index) => {
                            const action = 'view';
                            const target = this.rowData.value?.[index];
                            handleButtonClick({ target, action });
                        },
                        /**
                         * Handle grid action.
                         * @param {object} _
                         * @param {number} index
                         */
                        handleEdit: (_, index) => {
                            const action = 'edit';
                            const target = this.rowData.value?.[index];
                            handleButtonClick({ target, action });
                        },
                        /**
                         * Handle grid action.
                         * @param {object} _
                         * @param {number} index
                         */
                        handleDelete: (_, index) => {
                            const action = 'delete';
                            const target = this.rowData.value?.[index];
                            handleButtonClick({ target, action });
                        },
                        //
                        // handleAnalysis: (_, index) => {
                        //     const action = 'redirect';
                        //     const target = this.rowData.value?.[index];
                        // router.push(`/analysis?note=${id}`)
                        // this.handleButtonClick({ target, action });
                        // },
                    },
                },
                title: {
                    headerName: 'Note Title',
                    field: fields.title,
                    flex: 1.5,
                    minWidth: 75,
                    wrapText: true,
                    autoHeight: true,
                    sort: 'asc',
                    sortingOrder: ['asc', 'desc'],
                    comparator: useLocaleComparator,
                    valueGetter: (params) => {
                        const title = params?.data?.title;
                        return !!title && title !== '' ? title : null;
                    },
                    cellRenderer: (params) =>
                        params?.value ?? 'No title provided.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.title,
                    },
                },
                author: {
                    headerName: 'Author',
                    field: fields.author,
                    flex: 1.15,
                    minWidth: 100,
                    comparator: useLocaleComparator,
                    valueGetter: (params) => {
                        const author = !!params.data
                            ? params?.data?.author
                            : null;
                        const username =
                            !!author && !!author?.id ? author?.username : null;
                        return !!username ? username : null;
                    },
                    cellRenderer: (params) =>
                        params?.value ?? 'No author provided.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.author?.id,
                    },
                },
                content: {
                    headerName: 'Note Content',
                    field: fields.content,
                    flex: 3,
                    maxWidth: 200,
                    comparator: useLocaleComparator,
                    valueGetter: (params) => {
                        const content = params?.data?.content;
                        return !!content && content !== '' ? content : null;
                    },
                    cellRenderer: (params) =>
                        params?.value ?? 'No content provided.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.content,
                    },
                },
                startDate: {
                    headerName: 'Start Date',
                    field: fields.startDate,
                    flex: 1.25,
                    filter: false,
                    minWidth: 100,
                    valueFormatter: useDateLocalComponentFormat,
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.startDate,
                    },
                },
                endDate: {
                    headerName: 'End Date',
                    field: fields.endDate,
                    filter: false,
                    maxWidth: 100,
                    valueFormatter: useDateLocalComponentFormat,
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.endDate,
                    },
                },
                associationPath: {
                    headerName: 'Association',
                    field: fields.associationPath,
                    flex: 3,
                    minWidth: 200,
                    wrapText: true,
                    autoHeight: true,
                    valueGetter: usePathGetter,
                    cellRenderer: (params) => {
                        const path = params.value;
                        const formatted = path.split('/').join(' / ');
                        return !!path ? formatted : 'No association provided';
                    },
                },
            };
        };

        /** @returns {Readonly<Array<schema[keyof schema]>>} */
        const getColumnDefs = () => {
            const schema = getColumnSchema();
            return Object.freeze([
                schema.id,
                schema.title,
                schema.associationPath,
                schema.author,
                schema.startDate,
            ]);
        };

        // Lifecycle.

        onGridReady((params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        });

        onColumnResized((params) => {
            this.gridApi?.refreshCells();
        });

        onRowDataChanged(({ data = [] }) => {
            this.rowData.value = [...data];
        });

        onNoteSelected((row) => {
            this.selectedRow.value = row;
        });

        this.onButtonClicked((event) => {
            selectRow(event.target);
            console.log(`[${event.action}::note] ${event.target.id}`);
        });
    }
}
// <!-- COMPOSABLE -->

/**
 * Prepare note modal controller.
 * @param {Vue.Ref<Note.Row[]> | Note.Row[]} [initialState]
 */
export const useNoteGrid = (initialState = []) => {
    return new Grid(initialState);
};

// <!-- DEFAULT -->
export default useNoteGrid;
