<template>
    <div class="col-span-1 sm:col-span-6 mb-6">
        <LoadingWrapper :isLoading="isTreeRefreshing">
            <div
                v-if="isEditing"
                class="grid grid-cols-1"
            >
                <!-- HIERARCHY TREE SELECTORS -->
                <template
                    v-for="(node, index) of nodes"
                    :key="`${node.id}_${String(index)}`"
                >
                    <FormKit
                        v-if="node.selector !== null"
                        :id="node.selector.id"
                        type="select"
                        :name="node.selector.name"
                        :label="node.selector.label"
                        :placeholder="`All ${node.selector.label}s`"
                        :options="
                            modifySelectorOptions(
                                node.selector.options,
                                node.selector.label
                            )
                        "
                        :value="node.selector.value"
                        @node="(event) => node.selector.onFormKitNode(event)"
                        @input="
                            (value, event) => {
                                node.selector.onFormKitInput(value, event);
                                onSelectionUpdated();
                            }
                        "
                        :disabled="isHierarchyNodeDisabled(node)"
                        :classes="node.selector.classes()"
                        :ignore="true"
                        :preserve="true"
                    />
                    <!-- LOCATION SELECTOR -->
                    <FormKit
                        v-else
                        :id="node.locationSelector.id"
                        type="select"
                        :name="node.locationSelector.name"
                        :label="node.locationSelector.label"
                        :placeholder="`All ${node.locationSelector.label}s`"
                        :options="
                            modifySelectorOptions(
                                node.locationSelector.options,
                                node.locationSelector.label
                            )
                        "
                        :value="node.locationSelector.value"
                        @node="
                            (event) =>
                                node.locationSelector.onFormKitNode(event)
                        "
                        @input="
                            (value, event) => {
                                node.locationSelector.onFormKitInput(
                                    value,
                                    event
                                );
                                onSelectionUpdated();
                            }
                        "
                        :disabled="isLocationNodeDisabled(node)"
                        :classes="node.locationSelector.classes()"
                        :ignore="true"
                        :preserve="true"
                    />
                </template>
            </div>
            <div
                v-else
                class="flex flex-col"
            >
                <!-- HIERARCHY TREE DISPLAY -->
                <template
                    v-for="(node, index) of nodes"
                    :key="`${node.id}_${String(index)}`"
                >
                    <FormKit
                        v-if="node.selector !== null"
                        :id="node.selector.id"
                        type="text"
                        :name="node.selector.name"
                        :label="node.selector.label"
                        :placeholder="`All ${node.selector.label}s`"
                        :value="tree.getHierarchyNodeResource(node)?.name ?? ''"
                        :disabled="true"
                        :classes="modifyTextboxClasses(node.selector.classes())"
                        :ignore="true"
                        :preserve="true"
                    />
                    <!-- LOCATION SELECTOR -->
                    <FormKit
                        v-else
                        :id="node.locationSelector.id"
                        type="text"
                        :name="node.locationSelector.name"
                        :label="node.locationSelector.label"
                        :placeholder="`All ${node.locationSelector.label}s`"
                        :value="tree.getHierarchyNodeLocation(node)?.name ?? ''"
                        :disabled="true"
                        :classes="
                            modifyTextboxClasses(
                                node.locationSelector.classes()
                            )
                        "
                        :ignore="true"
                        :preserve="true"
                    />
                </template>
            </div>
        </LoadingWrapper>
    </div>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        ref,
        computed,
        watch,
        onMounted,
        onUnmounted,
    } from 'vue';

    // <!-- UTILITIES -->
    import { diff, jsonPatchPathConverter } from 'just-diff';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';

    // <!-- COMPOSABLES -->
    import { useFormkitDebugger } from '@/utils/FormKitDebugger';

    // <!-- MODELS -->
    import { NoteLocationResource } from '@/models/v1/notes/NoteLocation';
    import { NoteLocationHierarchyResource } from '@/models/v1/notes/NoteLocationHierarchy';
    import { LocationHierarchyResource } from '@/models/v1/locations/LocationHierarchy';
    import { HierarchyTreeNode } from '@/hooks/hierarchy/HierarchyTreeNode';
    import {
        HierarchyTree,
        HierarchyTreeContext,
    } from '@/hooks/hierarchy/HierarchyTree';

    // <!-- TYPES -->

    /** @typedef {globalThis.Account.Model} AccountResource */
    /** @typedef {import('@/models/v1/notes/Note').NoteResource} NoteResource */
    /** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */

    /** @typedef {import('@formkit/core').FormKitConfig["rootClasses"]} */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'NoteAssociationFields',
        components: {
            LoadingWrapper,
        },
        props: {
            dirtyNote: {
                /** @type {import('vue').PropType<Note.Form.EditData | Note.Form.AddData>} */
                type: Object,
            },
            isEditing: {
                /** @type {import('vue').PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {import('vue').PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** Debug mode. */
            debug: {
                type: Boolean,
                default: false, // HACK: Set to true to get debug mode content.
            },
        },
        emits: ['input'],
        setup(props, context) {
            // <!-- STATE -->
            /** @type {Vue.Ref<Vue.WatchStopHandle[]>} */
            const handlers = ref([]);

            /** @type {HierarchyTree} Tree context and API. */
            const tree = new HierarchyTree();

            /** @type {Vue.Ref<String[]>} Array of error messages. */
            const errors = ref([]);

            // <!-- COMPUTED PROPERTIES -->
            /** Computed hierarchy tree nodes. */
            const nodes = tree.nodes;

            /** @type {Vue.ComputedRef<Boolean>} Initialization status tracker. */
            const isTreeInitialized = tree.initialized;

            /** @type {Vue.ComputedRef<Boolean>} Refreshing status tracker. */
            const isTreeRefreshing = tree.refreshing;

            /** @type {Vue.ComputedRef<Note.Form.AddData | Note.Form.EditData>} Note resource reference. */
            const targetNote = computed(() => props.dirtyNote);

            // Get the source note, if one is present.
            const sourceNote = HierarchyTree.useComputedSourceNote(
                tree,
                targetNote.value
            );

            /**
             * Get the clean input.
             */
            const cleanInput = HierarchyTree.useComputedCleanInput(sourceNote);

            /**
             * Get the current output.
             */
            const dirtyOutput = HierarchyTree.useComputedDirtyOutput(tree);

            // <!-- GETTERS -->

            const modifyTextboxClasses = (classes) => {
                return {
                    ...classes,
                    inner: '$reset w-full cursor-not-allowed border-none',
                    input: '$reset w-full cursor-not-allowed border-none rounded-lg text-sm',
                };
            };

            const modifySelectorOptions = (options, label) => {
                return [{ label: `All ${label}s`, value: '' }, ...options];
            };

            /**
             * Determine if the node is disabled with a custom rule.
             *
             * @param {HierarchyTreeNode} node
             */
            const isHierarchyNodeDisabled = (node) => {
                if (node.isRoot) {
                    // Do not disable if root.
                    return false;
                } else {
                    // Disable if the node has no options or if the parent value is invalid.
                    const hasNoOptions = !node?.selector?.hasOptions;

                    const parent = node?.parent?.selector;
                    const parentHasNoOptions = !parent || !parent?.hasOptions;
                    const parentHasNoValue =
                        !parent ||
                        parent?.value === null ||
                        parent?.value === '';

                    return (
                        hasNoOptions || parentHasNoValue || parentHasNoOptions
                    );
                }
            };

            /**
             * Determine if the node is disabled with a custom rule.
             *
             * @param {HierarchyTreeNode} node
             */
            const isLocationNodeDisabled = (node) => {
                if (node.isRoot) {
                    // Do not disable if root.
                    return false;
                } else {
                    // Disable if the node has no options or if the parent value is invalid.
                    const hasNoOptions = !node?.locationSelector?.hasOptions;

                    const parent = node?.parent?.selector;
                    const parentHasNoValue =
                        !parent ||
                        parent?.value === null ||
                        parent?.value === '';

                    return hasNoOptions || parentHasNoValue;
                }
            };

            // <!-- EVENT HANDLERS -->
            // Register init callback.
            tree.onInit(() => {
                // Determine if hierarchy and locations are present.
                const hasHierarchy =
                    targetNote.value.parentHierarchyId !== null;
                const hasLocation = targetNote.value.parentLocationId !== null;

                // Get hierarchy between 0 and 4 elements long.
                const hierarchy = hasHierarchy
                    ? targetNote.value.associationHierarchy
                    : [];

                // Prepare the seed.
                const seed = {
                    /** @type {NoteLocationResource} */
                    targetLocation: null,
                    /** @type {LocationHierarchyResource[]} */
                    targetHierarchy: [],
                };

                // Check if location path.
                const isLocationPath = hasLocation;

                // Use first location, if any are present, as the note location.
                seed.targetLocation = isLocationPath
                    ? targetNote.value.parentLocation
                    : null;

                // Use location hierarchy, if location is present...
                if (
                    !!seed.targetLocation &&
                    !!seed.targetLocation.id &&
                    !!seed.targetLocation.hierarchyId
                ) {
                    const ids = seed.targetLocation.hierarchy.map((h) =>
                        String(h.id)
                    );
                    seed.targetHierarchy =
                        tree.findHierarchyIndexResources.bind(tree)(ids);
                } else {
                    // Clear target location if invalid or if none were present.
                    seed.targetLocation = null;
                    // Get leaf and its ancestors as the selected hierarchy.
                    const leaf = !!targetNote.value.parentHierarchyId
                        ? String(targetNote.value.parentHierarchyId)
                        : null;
                    seed.targetHierarchy = hasHierarchy
                        ? tree.getAncestorsAndSelf.bind(tree)(leaf)
                        : [];
                }

                // Initialize each node in the list.
                for (const node of tree.nodes.value) {
                    // Prepare the selected wrapper for the current node.
                    const selected = {
                        /** @type {HierarchyTreeNode} */
                        parent: null,
                        /** @type {LocationHierarchyResource[]} */
                        options: [],
                        /** @type {LocationHierarchyResource} */
                        hierarchy: null,
                        /** @type {Boolean} */
                        isLocationDropdown: false,
                    };

                    // Assign node parent.
                    selected.parent = node.parent;
                    // Assign node options. Roots are used when no parent is available.
                    selected.options =
                        tree.getHierarchyNodeOptions.bind(tree)(node);
                    // Assign location input status.
                    selected.isLocationDropdown =
                        node.locationSelector !== null;
                    // Assign node hierarchy value. No resource provided when node is the leaf.
                    selected.hierarchy =
                        hasHierarchy || hasLocation
                            ? seed.targetHierarchy[
                                  selected.isLocationDropdown
                                      ? node.depth - 1
                                      : node.depth
                              ]
                            : null;

                    // Initialize nodes...
                    if (selected.isLocationDropdown) {
                        // If location dropdown...
                        const value = !!seed.targetLocation
                            ? String(seed.targetLocation.id)
                            : '';
                        const options = !!selected.hierarchy
                            ? selected.hierarchy.locations
                            : [];
                        node.locationSelector.init({ value, options });
                    } else {
                        // If hierarchy dropdown...
                        const value = !!selected.hierarchy
                            ? String(selected.hierarchy.id)
                            : '';
                        const options = !!selected.options
                            ? selected.options
                            : [];
                        node.selector.init({ value, options });
                    }

                    // Initialize the text.
                    if (node.textbox !== null) {
                        node.textbox.init({
                            value: selected?.hierarchy?.name ?? '',
                        });
                    }

                    // Prefer the selectors.
                    node.preferSelectMode = true;
                }
                console.log(`Tree initialized.`);
            });

            // Register created callback.
            tree.onTreeCreated(() => {
                // Get the parent.
                const parent = nodes.value[nodes.value.length - 1] ?? null;

                // Get the context.
                const context =
                    parent?._context ?? new HierarchyTreeContext(tree);

                // Create new location node.
                const leaf = HierarchyTreeNode.create(context, parent);
                HierarchyTreeNode.addLocationSelector(leaf);

                // Append additional location node.
                tree.append(leaf);

                console.log(`Tree created.`);
            });

            /**
             * Initialize the hierarchy tree nodes.
             */
            const onInitHierarchyTree = async () => {
                // Initialize the tree nodes.
                await tree.init();
            };

            /**
             * Get the current output and update the source note resource.
             */
            const onSelectionUpdated = () => {
                const nodes = tree.nodes.value;
                const result = {
                    /** @type {String} */
                    hierarchyId: null,
                    /** @type {NoteLocationResource} */
                    location: null,
                };
                for (const node of nodes) {
                    if (node.selector !== null) {
                        if (
                            node.selector.value === null ||
                            node.selector.value === ''
                        ) {
                            break;
                        } else {
                            result.hierarchyId = String(node.selector.value);
                            result.location = null;
                            continue;
                        }
                    } else if (node.locationSelector !== null) {
                        if (
                            node.locationSelector.value === null ||
                            node.locationSelector.value === ''
                        ) {
                            result.hierarchyId = String(
                                node.parent.selector.value
                            );
                            result.location = null;
                            break;
                        } else {
                            const locationId = node.locationSelector.value;
                            const hierarchyId = String(
                                node.parent.selector.value
                            );
                            const locations =
                                tree.findHierarchyLocations(hierarchyId);
                            const location = locations.find(
                                (l) => String(l.id) === locationId
                            );
                            result.hierarchyId = hierarchyId;
                            result.location = !!location
                                ? new NoteLocationResource({
                                      id: location?.id,
                                      name: location?.name,
                                      hierarchyId: location?.hierarchyId,
                                      hierarchy:
                                          location?.hierarchy?.map(
                                              (h) =>
                                                  new NoteLocationHierarchyResource(
                                                      h
                                                  )
                                          ) ?? [],
                                  })
                                : null;
                            break;
                        }
                    }
                }

                // Update resource.
                const update = {
                    locations: !!result.location ? [result.location] : [],
                    locationId: !!result.location ? result.location?.id : null,
                    hierarchyId: !!result.hierarchyId
                        ? Number(result.hierarchyId)
                        : null,
                    hierarchy: !!result.hierarchyId
                        ? tree.getAncestorsAndSelf.bind(tree)(
                              result.hierarchyId
                          )
                        : [],
                };
                context.emit('input', update);
            };

            // <!-- LIFECYCLE -->

            /** onMounted - Register watchers. */
            onMounted(() => {
                // Register fresh set of handlers.
                handlers.value = [
                    watch(
                        [() => props.isEditing, () => props.dirtyNote],
                        (next, previous) => {
                            const [
                                nextEditing = true,
                                nextDirtyNote = { id: 'next' },
                            ] = next;
                            const [
                                previousEditing = false,
                                previousDirtyNote = { id: null },
                            ] = previous;

                            // Determine if component reload is necessary.
                            if (nextEditing !== previousEditing) {
                                // If the edit mode has changed...
                                const differences = diff(
                                    nextDirtyNote ?? {},
                                    previousDirtyNote ?? {},
                                    jsonPatchPathConverter
                                );
                                const isDirty = differences?.length > 0;
                                console.log('dirty?', differences);
                                if (isDirty) {
                                    onInitHierarchyTree();
                                }
                            }
                        },
                        {
                            immediate: true,
                            flush: 'pre',
                        }
                    ),
                ];
            });

            /** onUnmounted - Unregister watchers. */
            onUnmounted(() => {
                // Check if handlers available.
                if (handlers?.value?.length > 0) {
                    // Call stopper for each handle.
                    handlers.value?.forEach((h) => h?.());
                }
            });

            // onCreated - Initialize the hierarchy tree for the first time.
            onInitHierarchyTree();

            // <!-- DEBUG -->
            const { getDebugInfo } = useFormkitDebugger(
                ref('assign-location-hierarchy-fields'),
                cleanInput,
                dirtyOutput
            );

            // <!-- EXPOSE -->
            return {
                tree,
                nodes,
                errors,
                sourceNote,

                getDebugInfo,
                isTreeRefreshing,
                isTreeInitialized,
                onSelectionUpdated,
                modifySelectorOptions,
                modifyTextboxClasses,
                isHierarchyNodeDisabled,
                isLocationNodeDisabled,

                cleanInput,
                dirtyOutput,
            };
        },
    });
</script>
@/features/note-manager/hooks/deprecated_useNoteManager@/features/note-manager/hooks/deprecated_useNoteManager
