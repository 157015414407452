// <!-- UTILITIES -->
import { createEventHook } from '@vueuse/core';

// <!-- TYPES -->
/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef UseRowDataChangedEventReturn
 * @prop {import('@vueuse/core').EventHook<{ data?: TData[] }>} rowDataChange Event hook that will trigger when row data should be updated.
 * @prop {import('@vueuse/core').EventHookOn<{ data?: TData[] }>} onRowDataChanged Invoke callback that will consume the data payload when the `rowDataChange` event is triggered.
 * @prop {import('@vueuse/core').EventHookTrigger<{ data?: TData[] }>} changeRowData Trigger `rowDataChange` event hook.
 */

/**
 * Define event hook.
 * @template [TData=any] AgGrid row node data type.
 * @returns {UseRowDataChangedEventReturn<TData>}
 */
export const useRowDataChangedEvent = () => {
    const rowDataChange = createEventHook();
    return {
        rowDataChange,
        onRowDataChanged: rowDataChange.on,
        changeRowData: rowDataChange.trigger,
    };
};

// <!-- DEFAULT -->
export default useRowDataChangedEvent;
