<template>
    <div
        v-if="isVisible"
        :id="`debug-outer-${id}`"
    >
        <div
            :id="`debug-inner-${id}`"
            class="mx-2 my-2 font-mono text-xs bg-gray-50"
        >
            <div class="flex justify-between">
                <h3
                    class="text-xs rounded-t-lg px-2 py-2 text-gray-600 bg-gray-50"
                >
                    Debug Frame
                </h3>
                <button
                    class="text-xs underline rounded-t-lg px-2 py-2 text-gray-600 bg-gray-50"
                    @click.prevent="toggleHidden"
                >
                    {{ hidden ? 'Show' : 'Hide' }}
                </button>
            </div>
            <transition
                appear
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 -translate-y-2"
                enter-to-class="transform opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="transform opacity-100 translate-y-0"
                leave-to-class="transform opacity-0 -translate-y-2"
            >
                <div v-if="!hidden">
                    <div
                        v-if="isSingle"
                        :id="`debug-single-${id}`"
                        class="flex flex-row gap-x-2 group rounded-lg px-4 py-4 text-gray-600 hover:text-gray-900 bg-gray-50 hover:bg-gray-100"
                    >
                        <div
                            class="flex-none max-w-48 overflow-x-auto whitespace-pre-line"
                        >
                            {{ first.label }}
                        </div>
                        <div
                            class="flex-1 border-l-2 border-dashed border-gray-100 group-hover:border-gray-200 px-2 overflow-x-auto whitespace-pre-wrap"
                        >
                            {{ first.item }}
                        </div>
                    </div>
                    <ul
                        v-else
                        class="mt-2 list-none text-xs rounded-lg text-gray-600 hover:text-gray-900 bg-gray-50 grid grid-cols-1 px-2 py-2 gap-y-4"
                    >
                        <li
                            v-for="({ label, item }, index) of output"
                            :key="index"
                            class="flex flex-col gap-x-2 group px-2 py-2 rounded-lg hover:bg-gray-100"
                        >
                            <div
                                class="flex-none max-w-48 overflow-x-auto whitespace-pre-line"
                            >
                                {{ label }}
                            </div>
                            <div
                                class="flex-1 border-l-2 border-dashed border-gray-100 group-hover:border-gray-200 px-2 overflow-x-auto whitespace-pre-wrap"
                            >
                                {{ item }}
                            </div>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref, computed, toRefs } from 'vue';

    // <!-- UTILITIES -->
    import ActionLogger from '@/utils/ActionLogger';
    import debounce from 'just-debounce-it';
    import omit from 'just-omit';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DebugFrame',
        props: {
            id: {
                type: String,
                default: '0',
            },
            debug: {
                type: Boolean,
                default: false,
            },
            startHidden: {
                type: Boolean,
                default: true,
            },
            data: {
                /** @type {V.PropType<Array<{ label: string, name?: string, id: string, data: string }>>} */
                type: Array,
                default: () => {
                    ActionLogger.log('[debug::inspect]').warning(
                        'No data property provided. Using placeholder information.'
                    );
                    return [
                        {
                            label: 'Missing Debug Data',
                            id: 'Example',
                            data: 'Update your component props to remove this message.',
                        },
                        {
                            label: 'Missing Debug Data',
                            id: 'Example',
                            data: 'Update your component props to remove this message.',
                        },
                        {
                            label: 'Missing Debug Data',
                            id: 'Example',
                            data: 'Update your component props to remove this message.',
                        },
                        {
                            label: 'Missing Debug Data',
                            id: 'Example',
                            data: 'Update your component props to remove this message.',
                        },
                    ];
                },
            },
        },
        setup(props) {
            // ==== PROPS ====
            const { debug, data, startHidden } = toRefs(props);

            // ==== STATE ====
            const hidden = ref(startHidden.value);

            // ==== COMPUTED ====
            const output = computed(() => {
                return data.value.map((item, index) => {
                    const label = item?.label ?? item?.name ?? `Item`;
                    const listIndex = `[${index}]`;
                    // EXPOSE
                    return {
                        label: `${label}${
                            item?.id === undefined ? ` ` : ` (${item.id}) `
                        }${listIndex}`,
                        item: omit(item, 'label'),
                    };
                });
            });
            const isVisible = computed(
                () =>
                    debug.value === true &&
                    !!data.value &&
                    data.value.length > 0
            );
            const isSingle = computed(
                () => isVisible.value && data.value.length === 1
            );
            const first = computed(() => output.value[0]);

            // ==== METHODS ====
            const toggleHidden = debounce(() => {
                hidden.value = hidden.value === false ? true : false;
            }, 250);

            // ==== EXPOSE ====
            return {
                isVisible,
                isSingle,
                first,
                output,
                hidden,
                toggleHidden,
            };
        },
    });
</script>
