<template>
    <div>
        <FormSectionHeader v-bind="{ title, description }">
            <template #title><slot name="title"></slot></template>
            <template #description><slot name="description"></slot></template>
        </FormSectionHeader>
        <FormSectionContent v-bind="{ grid }">
            <!-- CONTENT -->
            <slot></slot>
        </FormSectionContent>
        <HorizontalRule v-bind="{ span }" />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import FormSectionHeader from '@/components/forms/partials/FormSectionHeader.vue';
    import FormSectionContent from '@/components/forms/partials/FormSectionContent.vue';
    import { HorizontalRule } from '@/components/vnodes/HorizontalRule.js';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FormSection',
        components: {
            FormSectionContent,
            FormSectionHeader,
            HorizontalRule,
        },
        props: {
            /** Form section title. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
            /** Form section description. */
            description: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
            /** Array of tailwindcss grid classes, used to style the content grid. */
            grid: {
                /** @type {V.PropType<String[]>} */
                type: Array,
                default: () => null,
            },
            /** Array of span classes, used to style the section separator. */
            span: {
                /** @type {V.PropType<String[]>} */
                type: Array,
                default: () => null,
            },
        },
    });
</script>
