// <!-- API -->
import { computed } from 'vue';
import { useStore } from 'vuex';

// <!-- COMPOSABLE -->
/**
 * Define composable that can optionally accept an already defined store.
 * @param {Object} [props]
 * @param {import('vuex').Store<import('@/store/types/ECNBStore').ECNBState>} [props.store]
 */
export const useDataAnalystGate = (props = {}) => {
    // PROPS
    const { store = useStore() } = props;

    // STATE
    const organization = computed(() => store.state.accounts.organization);
    const user = computed(() => store.state.users.me);
    const role = computed(() => {
        return user.value?.isSuperUser === true
            ? 'admin'
            : organization.value?.access?.userRole;
    });

    // CONDITIONALS
    const isDataAnalyst = computed(() => role.value === 'data-analyst');
    const isNotDataAnalyst = computed(() => isDataAnalyst.value !== true);

    // EXPOSE
    return {
        role,
        isDataAnalyst,
        isNotDataAnalyst,
    };
};

// <!-- DEFAULT -->
export default useDataAnalystGate;
