// <!-- UTILITIES -->
import { ref } from 'vue';
import { resolveRef, isDefined } from '@vueuse/core';

// <!-- TYPES -->
/**
 * @typedef UseAgGridDOMLayoutOptions
 * @prop {string | V.Ref<string>} [domLayout] Possibly reactive value controlling DOM layout of the grid.
 */

/**
 * @typedef UseAgGridDOMLayoutReturn
 * @prop {Readonly<V.Ref<string>>} domLayout Reactive DOM layout value.
 */

/**
 * Define reactive DOM layout settings.
 * @param {UseAgGridDOMLayoutOptions} [props]
 * @returns {UseAgGridDOMLayoutReturn}
 */
export const useAgGridDOMLayout = (props = {}) => {
    /** @type {V.Ref<string> | V.ComputedRef<string>} */
    const domLayout = isDefined(props.domLayout)
        ? resolveRef(props.domLayout)
        : ref('autoHeight');
    return {
        domLayout,
    };
};

// <!-- DEFAULT -->
export default useAgGridDOMLayout;
