<template>
    <div>
        <FormSectionTitle v-bind="{ title }">
            <slot name="title"></slot>
        </FormSectionTitle>
        <FormSectionDescription v-bind="{ description }">
            <slot name="description"></slot>
        </FormSectionDescription>
    </div>
</template>

<script>
    /**
     * If slots are not used, fallback content will rely on props.
     */

    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import FormSectionTitle from '@/components/forms/partials/FormSectionTitle.vue';
    import FormSectionDescription from '@/components/forms/partials/FormSectionDescription.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FormSectionHeader',
        components: {
            FormSectionTitle,
            FormSectionDescription,
        },
        props: {
            /** Form section title. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
            /** Form section description. */
            description: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
        },
    });
</script>
