<template>
    <div class="px-6 pb-5 border-b border-gray-200 sm:pb-0 hidden sm:block">
        <div class="mt-3 sm:mt-4">
            <nav class="-mb-px flex space-x-8">
                <router-link
                    v-for="tab in subTabs"
                    :key="tab.name"
                    :to="tab.to"
                    :class="[
                        tab.current
                            ? 'border-primary-800 text-primary-800'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
                    ]"
                    :aria-current="tab.current ? 'page' : undefined"
                >
                    {{ tab.name }}
                </router-link>
                <template v-if="includeExternalLinks">
                    <span class="flex">
                        <a
                            class="border-transparent align-middle text-gray-500 hover:text-gray-700 focus:text-primary-800 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                            :href="Links.RESOURCES"
                            target="_blank"
                        >
                            Resources
                            <ExternalLinkIcon
                                class="inline-block relative -top-0.5 h-4 w-4 text-primary-700"
                                aria-hidden="true"
                            />
                        </a>
                    </span>
                    <span class="flex">
                        <a
                            class="border-transparent text-gray-500 hover:text-gray-700 focus:text-primary-800 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                            :href="Links.DEW_POINT_CALCULATOR"
                            target="_blank"
                        >
                            Dew Point Calculator
                            <ExternalLinkIcon
                                class="inline-block relative -top-0.5 h-4 w-4 text-primary-700"
                                aria-hidden="true"
                            />
                        </a>
                    </span>
                </template>
            </nav>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import { ExternalLinkIcon } from '@heroicons/vue/outline';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SubNav',
        props: {
            subTabs: {
                /** @type {V.PropType<SN.Tab[]>} */
                type: Array,
                required: true,
            },
            includeExternalLinks: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            ExternalLinkIcon,
        },
        setup(props) {
            // ==== STATE ====
            const Links = Object.freeze({
                RESOURCES:
                    'https://imagepermanenceinstitute.org/education/publications.html',
                // RESOURCES: getMarketingLink('resources/docs/getting-started'),
                DEW_POINT_CALCULATOR: 'http://dpcalc.org',
            });

            // ==== EXPOSE ====
            return {
                Links,
            };
        },
    });
</script>
