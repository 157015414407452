// <!-- TYPES -->

/**
 * @template [TData=any]
 * @template {string} [TFields=string]
 * @template {{ [K in TFields]: { field: K } & Omit<ColumnDef<TData>, 'field'> }} [TSchema={ [K in TFields]: { field: K } & Omit<ColumnDef<TData>, 'field'> }]
 * @typedef UseAgGridColumnDefsOptions
 * @prop {AgGrid.ColumnDef<TData>} [defaultColumnDef] Default column definition.
 * @prop {Readonly<TSchema>} [columnSchema] Column schema that will provide column definitions, by fieldname.
 */

/**
 * @template [TData=any]
 * @template {string} [TFields=string]
 * @template {{ [K in TFields]: { field: K } & Omit<ColumnDef<TData>, 'field'> }} [TSchema={ [K in TFields]: { field: K } & Omit<ColumnDef<TData>, 'field'> }]
 * @typedef UseAgGridColumnDefsReturn
 * @prop {() => Readonly<AgGrid.ColumnDef<TData>>} getDefaultColumnDef Default column definition.
 * @prop {(fields: readonly TFields[]) => ColumnDef[]} getColumnDefs Reactive, selected column definitions.
 */

/**
 * Define column definition properties.
 * @template [TData=any]
 * @template {string} [TFields=string]
 * @template {{ [K in TFields]: { field: K } & Omit<ColumnDef<TData>, 'field'> }} [TSchema={ [K in TFields]: { field: K } & Omit<ColumnDef<TData>, 'field'> }]
 * @param {UseAgGridColumnDefsOptions<TData,TFields,TSchema>} [props]
 * @returns {UseAgGridColumnDefsReturn<TData,TFields,TSchema>}
 */
export const useAgGridColumnDefs = (props = {}) => {
    return {
        getDefaultColumnDef: () => {
            return Object.freeze(props.defaultColumnDef ?? {});
        },
        getColumnDefs: (fields) => {
            return fields.map((key) => ({ ...props.columnSchema[key] }));
        },
    };
};

// <!-- DEFAULT -->
export default useAgGridColumnDefs;
