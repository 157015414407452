<template>
    <BasicModal
        :show="enabled.has('add')"
        @close="cancelAdd"
    >
        <AddNoteForm
            :sourceNote="addTarget"
            :isEditing="true"
            :isLoading="isLoading"
            @cancel="cancelAdd"
            @submit="submitAdd"
            :debug="isDebug"
        />
    </BasicModal>
    <BasicModal
        :show="enabled.has('edit')"
        @close="cancelUpdate"
    >
        <EditNoteForm
            :sourceNote="editTarget"
            :isEditing="isEditing"
            :isLoading="isLoading"
            @switch="toggleEditing"
            @cancel="cancelUpdate"
            @submit="submitUpdate"
            :debug="isDebug"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete Note"
        confirmationText="Are you sure you want to delete this note? This action cannot be undone."
        :open="enabled.has('delete')"
        @cancel="cancelDelete({ reason: $event, formData: deleteTarget })"
        @delete="submitDelete({ formData: deleteTarget })"
    />
    <NoteManagerContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <PageDescription>
                        {{ page.description }}
                    </PageDescription>
                    <ModalButton
                        v-if="canUserAddNote"
                        variant="primary"
                        label="Add Note"
                        :busy="isLoading"
                        @click="clickAddButton"
                    />
                </PageIntro>
                <LoadingWrapper :isLoading="isLoading">
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="pagination.enabled.value"
                        :paginationPageSize="pagination.size.value"
                        :overlayNoRowsTemplate="emptyMessage"
                        :columnDefs="columnDefs"
                        :rowHeight="null"
                        :rowData="rowData"
                        :defaultColDef="defaultColDef"
                        @grid-ready="handleGridReady"
                        @column-resized="handleColumnResized"
                    />
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </NoteManagerContainer>
    <ToastOverlay
        :toasts="toasts"
        @dismiss="handleToastDismissal"
    />
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';
    import ToastOverlay from '@/components/toasts/ToastOverlay.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import PageWrapper from '@/components/PageWrapper.vue';
    import BasicModal from '@/components/BasicModal.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import NoteManagerContainer from '~NoteManager/components/wrappers/NoteManagerContainer.vue';
    import AddNoteForm from '~NoteManager/components/forms/AddNoteForm.vue';
    import EditNoteForm from '~NoteManager/components/forms/EditNoteForm.vue';
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import { useNoteManager } from '~NoteManager/hooks';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'NoteManager',
        components: {
            ModalButton,
            ToastOverlay,
            LoadingWrapper,
            PageIntro,
            PageDescription,
            PageWrapper,
            BasicModal,
            ConfirmDeleteModal,
            NoteManagerContainer,
            AddNoteForm,
            EditNoteForm,
            AgGridVue,
        },
        setup(props, context) {
            // ==== DEPENDENCIES ====
            const manager = useNoteManager();

            // ==== CONSTANTS ====
            const { page, grid, forms, modals, toasts } = manager;
            const { emptyMessage, defaultColDef } = grid;

            // ==== REACTIVITY ====
            const { pagination, columnDefs, rowData } = grid;
            const { enabled } = modals;

            // ==== CONDITIONALS ====
            const { isDebug, isLoading, canUserAddNote } = manager;

            // ==== EVENT TRIGGERS ====
            const { handleGridReady, handleColumnResized } = grid;
            const { clickAddButton, handleToastDismissal } = manager;

            // // ==== EXPOSE ====
            return {
                // MANAGER
                isDebug,
                isLoading,
                canUserAddNote,
                // MODALS
                enabled,
                // TOASTS
                toasts: toasts.queue,
                handleToastDismissal,
                // GRID
                page,
                pagination,
                emptyMessage,
                defaultColDef,
                columnDefs,
                rowData,
                handleGridReady,
                handleColumnResized,
                // FORMS
                clickAddButton,
                addTarget: forms.add.target,
                cancelAdd: forms.add.cancel,
                submitAdd: forms.add.submit,
                isEditing: forms.edit.isEditing,
                toggleEditing: forms.edit.toggleEditing,
                editTarget: forms.edit.target,
                cancelUpdate: forms.edit.cancel,
                submitUpdate: forms.edit.submit,
                deleteTarget: forms.delete.target,
                cancelDelete: forms.delete.cancel,
                submitDelete: forms.delete.submit,
            };
        },
    });
</script>
