import { LocationPayload } from '@/payloads/v2/locations/LocationPayload';

// <!-- UTILITIES ->
import { Maybe } from 'true-myth/dist/maybe';

/**
 * Resource model.
 * @implements {globalThis.Location.Model}
 */
export class Location {
    /** Get the static factory methods. */
    static get create() {
        return {
            /** @type {ModelConstructor<globalThis.Location.Model, globalThis.Location.Payload>} */
            fromPayload: (payload) => new Location(payload),
        };
    }

    /**
     * Convert model into a payload format.
     *
     * @param {globalThis.Location.Model} model
     * @returns {globalThis.Location.Payload}
     */
    static createPayload(model) {
        return LocationPayload.create.fromData({
            id: Number.isNaN(model.id) ? null : model.id,
            name: model.name,
            min_date: model.minDate,
            max_date: model.maxDate,
            data_logger_manufacturer: model.dataLoggerManufacturer,
            data_logger_serial_number: model.dataLoggerSerialNumber,
            data_logger_placement: model.dataLoggerPlacement,
            collections_contact: model.collectionsContact,
            facilities_contact: model.facilitiesContact,
            department_or_division: model.departmentOrDivision,
            air_source: model.airSource,
            bms_id: Number.isNaN(model.bmsId) ? null : model.bmsId,
            zone: model.zone,
            photo: model.photo,
            floorplan: model.floorplan,
            timezone: {
                timezone_type: 3,
                timezone: model.timezone,
            },
            created_at: model.createdAt?.toISOString(),
            updated_at: model.updatedAt?.toISOString(),
            last_upload_at: model.lastUploadAt?.toISOString(),
            location_hierarchy_id: Number.isNaN(model.hierarchyId)
                ? null
                : model.hierarchyId,
            hierarchy: model.hierarchy,
            nara_standard: model.naraStandard,
        });
    }

    /**
     * Construct a payload instance.
     * @param {Partial<globalThis.Location.Payload>} data
     */
    constructor(data) {
        this.id = Maybe.of(data.id).unwrapOr(NaN);
        this.name = data.name;
        this.serial = Maybe.of(data.serial).unwrapOr(null);
        const min_date = Maybe.of(data.min_date);
        this.minDate = min_date.isJust ? new Date(min_date.value) : null;
        const max_date = Maybe.of(data.max_date);
        this.maxDate = max_date.isJust ? new Date(max_date.value) : null;
        this.dataLoggerManufacturer = Maybe.of(
            data.data_logger_manufacturer
        ).unwrapOr(null);
        this.dataLoggerSerialNumber = Maybe.of(
            data.data_logger_serial_number
        ).unwrapOr(null);
        this.dataLoggerPlacement = Maybe.of(
            data.data_logger_placement
        ).unwrapOr(null);
        this.collectionsContact = Maybe.of(data.collections_contact).unwrapOr(
            null
        );
        this.facilitiesContact = Maybe.of(data.facilities_contact).unwrapOr(
            null
        );
        this.departmentOrDivision = Maybe.of(
            data.department_or_division
        ).unwrapOr(null);
        this.airSource = Maybe.of(data.air_source).unwrapOr(null);
        this.bmsId = Maybe.of(data.bms_id).unwrapOr(NaN);
        this.zone = Maybe.of(data.zone).unwrapOr(null);
        this.photo = Maybe.of(data.photo).unwrapOr(false);
        this.floorplan = Maybe.of(data.floorplan).unwrapOr(false);
        this.timezone = Maybe.of(data.timezone?.timezone).unwrapOr(
            'America/New_York'
        );
        const created_at = Maybe.of(data.created_at);
        this.createdAt = created_at.isJust ? new Date(created_at.value) : null;
        const updated_at = Maybe.of(data.updated_at);
        this.updatedAt = updated_at.isJust ? new Date(updated_at.value) : null;
        const last_upload_at = Maybe.of(data.last_upload_at);
        this.lastUploadAt = last_upload_at.isJust
            ? new Date(last_upload_at.value)
            : null;
        this.hierarchyId = Maybe.of(data.location_hierarchy_id).unwrapOr(NaN);
        this.hierarchy = Maybe.of(data.hierarchy).unwrapOr(null);
        this.naraStandard = Maybe.of(data.nara_standard).unwrapOr(null);
    }

    /**
     * Convert model into a payload.
     */
    toPayload() {
        return Location.createPayload(this);
    }
}
