// <!-- UTILITIES -->
import { ref } from 'vue';

// <!-- TYPES -->
/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef UseAgGridAPIOptions
 * @prop {V.Ref<TData[]>} rowData Reactive row data that is tracked by this grid.
 */

/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef UseAgGridAPIReturn
 * @prop {V.Ref<AgGrid.GridApi<TData>>} grid Reactive reference to the grid api instance.
 * @prop {V.Ref<AgGrid.ColumnApi<TData>>} column Reactive reference to the column api instance.
 */

/**
 * Define common api used by an AgGrid instance.
 * @template [TData=any]
 * @param {UseAgGridAPIOptions<TData>} props
 * @returns {UseAgGridAPIReturn<TData>}
 */
export const useAgGridAPI = (props) => {
    return {
        grid: ref(null),
        column: ref(null),
    };
};

// <!-- DEFAULT -->
export default useAgGridAPI;
