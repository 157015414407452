// <!-- API -->
import { computed, onMounted } from 'vue';
import { computedEager, promiseTimeout } from '@vueuse/core';
import { useStore } from 'vuex';
import { useDataAnalystGate } from '@/hooks/gates';
import { useNoteGrid, useNoteModals, useNoteIndex, useNoteForms } from '.';
import { fetchOrganizationUsers } from '@/api/v2/organizations';
import {
    fetchNoteById,
    createNote,
    updateNoteById,
    deleteNoteById,
} from '@/api/v2/notes';

// <!-- UTILITIES -->
import clone from 'just-clone';
import { AssociationType } from '@/enums';
import { useToastOverlay } from '@/components/toasts/hooks/useToastOverlay';

// <!-- CLASS -->
class Manager {
    /**
     * Instantiate the manager.
     */
    constructor() {
        // Services.
        /** @type {import('vuex').Store<import("@/store/types/ECNBStore").ECNBState>} */
        this.store = useStore();
        this.grid = useNoteGrid();
        this.modals = useNoteModals();
        this.index = useNoteIndex();
        this.toasts = useToastOverlay();
        this.loader = this.index.loader;
        this.gate = useDataAnalystGate({ store: this.store });

        // Form
        const forms = useNoteForms();
        this.forms = {
            add: {
                template: forms.template.add,
                ...forms.state.add,
            },
            edit: {
                template: forms.template.edit,
                ...forms.state.edit,
            },
            delete: {
                template: forms.template.delete,
                ...forms.state.delete,
            },
        };

        // Event Triggers.

        // State
        this.defineConstants();
        this.defineState();
        this.defineReadonly();
        this.defineConditionals();

        // Methods.
        this.defineToastHelpers();
        this.exposeMethods();

        // Lifecycle.
        this.registerMountedEventListener();
        this.registerSuccessEventListener();
        this.registerFormEventListeners(forms);
    }

    defineConstants() {
        this.page = {
            description:
                'View, edit, delete, and add notes. Sort notes by clicking on a column header, or search by Note Title, Association, or Author by typing in the search boxes.',
        };
    }

    defineState() {
        /** @type {Map<number,User.Model>} */
        this.users = new Map();
    }

    defineReadonly() {
        this.user = computed(() => this.store.state.users.me);
        this.account = computed(() => this.store.state.accounts.account);
        this.organization = computed(
            () => this.store.state.accounts.organization
        );
        this.timezone = computed(() => {
            const account = this.account.value;
            const organization = this.organization.value;
            /** @type {TimeZone.Identifier} */
            let timezone = null;
            timezone ??= account?.timezone;
            timezone ??= organization?.timezone;
            timezone ??= 'UTC';
            return timezone;
        });
    }

    defineConditionals() {
        // Conditionals.
        this.isDebug = computedEager(
            () => process.env.NODE_ENV !== 'production'
        );
        this.isLoading = this.loader.isLoading;
        this.canUserAddNote = computed(() => this.gate.isNotDataAnalyst.value);
    }

    defineToastHelpers() {
        /**
         * Clear all toasts in the queue.
         * @param {number} [delay] Milliseconds.
         */
        this.clearToasts = async (delay = 100) => {
            const items = [...this.toasts.queue.value];
            for (const item of items) {
                await promiseTimeout(delay, false, 'Staggered dismissals.');
                item.dismiss({ force: true });
            }
        };

        /**
         * Create and queue a toast with reasonable defaults.
         * @param {Toasts.ToastOptions & { override?: boolean }} [props]
         */
        this.makeToast = (props = {}) => {
            // PROPS
            const {
                id = 'unknown',
                type = 'default',
                title = '',
                content = [],
                description = '',
                dismissable = true,
                lifetime = 5000,
                override = true,
            } = props;

            // OVERRIDE
            if (!!override) {
                // Dismiss previous toast, if collision found.
                this.toasts.dismiss(id, true);
            }

            // QUEUE
            this.toasts.enqueue({
                id,
                type,
                title,
                content,
                description,
                dismissable,
                lifetime,
            });
        };

        /**
         * Dismiss toast by id.
         * @param {string} id
         */
        this.dismissToastById = (id) => {
            this.toasts.dismiss(id, true);
        };

        /**
         * Handle toast dismissal.
         * @param {{ event: MouseEvent, id: string, icon: Toasts.Icon, title: string }} event
         */
        this.handleToastDismissal = (event) => {
            this.dismissToastById(event.id);
        };

        /**
         * Helper function for quickly queueing notifications.
         * @param {Toasts.ToastOptions['type']} type
         * @param {string} action
         * @param {string | string[]} [payload]
         * @param {number} [lifetime]
         * @param {boolean} [dismissable]
         */
        this.notify = (
            action = 'Title',
            payload = undefined,
            lifetime = 0,
            dismissable = true,
            type = 'default'
        ) => {
            const id = `${type}-${action}`;
            const title = action;
            const useDescription =
                typeof payload === 'string' && payload.length > 0;
            const useContent =
                typeof payload !== 'string' &&
                Array.isArray(payload) &&
                payload.length > 0;
            /** @type {Toasts.ToastOptions} */
            const options = {
                id,
                type,
                title,
                description: useDescription ? payload : '',
                content: useContent ? payload : [],
                dismissable,
                lifetime,
            };
            // Send the toast.
            this.makeToast(options);
        };

        /**
         * Send an info toast.
         * @param {string} action
         * @param {string | string[]} [payload]
         * @param {number} [lifetime]
         * @param {boolean} [dismissable]
         */
        const info = (
            action = 'Info',
            payload = undefined,
            lifetime = 5000,
            dismissable = true
        ) => {
            console.info(action, payload);
            this.notify(action, payload, lifetime, dismissable, 'info');
        };

        /**
         * Send a warning toast.
         * @param {string} action
         * @param {string | string[]} [payload]
         * @param {number} [lifetime]
         * @param {boolean} [dismissable]
         */
        const warning = (
            action = 'Warning',
            payload = undefined,
            lifetime = 5000,
            dismissable = true
        ) => {
            console.warn(action, payload);
            this.notify(action, payload, lifetime, dismissable, 'warning');
        };

        /**
         * Send an error toast.
         * @param {string} action
         * @param {string | string[]} [payload]
         * @param {number} [lifetime]
         * @param {boolean} [dismissable]
         */
        const error = (
            action = 'Error',
            payload = undefined,
            lifetime = 5000,
            dismissable = true
        ) => {
            console.error(action, payload);
            this.notify(action, payload, lifetime, dismissable, 'error');
        };

        /**
         * Send an success toast.
         * @param {string} action
         * @param {string | string[]} [payload]
         * @param {number} [lifetime]
         * @param {boolean} [dismissable]
         */
        const success = (
            action = 'Success',
            payload = undefined,
            lifetime = 5000,
            dismissable = true
        ) => {
            console.log(action, payload);
            this.notify(action, payload, lifetime, dismissable, 'success');
        };

        // Namespace for sending toasts.
        this.send = {
            toast: this.notify,
            info,
            success,
            warning,
            error,
        };
    }

    exposeMethods() {
        /**
         * Convert note into form data.
         * @param {Note.Model} data
         * @returns {Note.Form.AddData}
         */
        this.toAddFormData = (data) => {
            return {
                form: 'add',
                id: null,
                title: data.title,
                authorId: data.author?.id,
                author: data.author?.username,
                content: data.content,
                associationType: data.associationType,
                associationHierarchy: data.associationHierarchy,
                parentHierarchyId: data.parentHierarchyId,
                parentHierarchy: data.parentHierarchy,
                parentLocationId: data.parentLocationId,
                parentLocation: data.parentLocation,
                timezone: this.timezone.value ?? 'UTC',
                dateStartZ: '',
                dateEndZ: '',
            };
        };

        /**
         * Convert note into form data.
         * @param {Note.Model} data
         * @returns {Note.Form.EditData}
         */
        this.toEditFormData = (data) => {
            // Get the ISO Zulu date strings.
            const dateStartISO =
                data.startDate == null ? null : data.startDate.toISOString();
            const dateEndISO =
                data.endDate == null ? null : data.endDate.toISOString();
            return {
                form: 'edit',
                id: data.id,
                title: data.title,
                authorId: data.author?.id,
                author: data.author?.username,
                content: data.content,
                associationType: data.associationType,
                associationHierarchy: data.associationHierarchy,
                parentHierarchyId: data.parentHierarchyId,
                parentHierarchy: data.parentHierarchy,
                parentLocationId: data.parentLocationId,
                parentLocation: data.parentLocation,
                timezone: this.timezone.value ?? 'UTC',
                dateStartZ: dateStartISO,
                dateEndZ: dateEndISO,
            };
        };

        /**
         * Click the add button.
         * @param {MouseEvent} event
         */
        this.clickAddButton = (event) => {
            // Reset the form state.
            const form = this.forms.add;
            const target = clone(form.template);
            const formData = this.toAddFormData(target);
            formData.authorId = this.user.value?.id;
            formData.author = this.user.value?.username;
            form.reset({ initialState: formData });
            form.editing();

            // Display the modal.
            this.modals.reveal('add');
        };

        /**
         * Click the view button.
         */
        this.clickViewButton = async () => {
            // Get the selected row.
            const selected = this.grid.selectedRow.value;

            // Fetch the selected row's full details.
            const result = await fetchNoteById({
                id: selected.id,
                account_id: this.account.value.id,
            });

            // Get the target.
            const note = result.isOk ? result.value : null;
            const author = this.users.get(note.authorId);
            const formData = this.toEditFormData(note);
            formData.authorId = author?.id ?? null;
            formData.author = author?.username ?? null;

            // Reset the form state.
            const form = this.forms.edit;
            form.reset({ initialState: formData });
            form.readonly();

            // Display the modal.
            this.modals.reveal('edit');
        };

        /**
         * Click the edit button.
         */
        this.clickEditButton = async () => {
            // Get the selected row.
            const selected = this.grid.selectedRow.value;

            // Fetch the selected row's full details.
            const result = await fetchNoteById({
                id: selected.id,
                account_id: this.account.value.id,
            });

            // Get the target.
            const note = result.isOk ? result.value : null;
            const author = this.users.get(note.authorId);
            const formData = this.toEditFormData(note);
            formData.authorId = author?.id ?? null;
            formData.author = author?.username ?? null;

            // Reset the form state.
            const form = this.forms.edit;
            form.reset({ initialState: formData });
            form.editing();

            // Display the modal.
            this.modals.reveal('edit');
        };

        /**
         * Click the delete button.
         */
        this.clickDeleteButton = () => {
            // Get the selected row.
            const selected = clone(this.grid.selectedRow.value);

            // Reset the form state.
            const form = this.forms.delete;
            form.reset({ initialState: selected });
            form.readonly();

            // Display the modal.
            this.modals.reveal('delete');
        };
    }

    /**
     * Define the callbacks.
     */
    registerMountedEventListener() {
        /**
         * Update the hierarchy labels.
         */
        const updateHierarchyLabels = () => {
            this.grid.labels.value = [...this.account.value.treeLabels];
        };

        /**
         * Update the display timezone.
         */
        const updateDisplayTimezone = () => {
            this.grid.displayTimezone.value =
                this.account.value?.timezone ?? 'UTC';
        };

        const updateUserRole = () => {
            const role = this.user?.value?.isSuperUser
                ? 'admin'
                : this.organization.value?.access?.userRole;
            this.grid.setUserRole(role);
        };

        /**
         * Fetch note index.
         */
        this.fetchNotes = async () => {
            updateUserRole();
            updateDisplayTimezone();
            updateHierarchyLabels();
            await fetchAuthors(); // Update the list.
            await this.index.fetchByAccountId(this.account.value.id);
        };

        /**
         * Fetch user details.
         */
        const fetchAuthors = async () => {
            // Get the user details.
            try {
                this.loader.start();
                const result = await fetchOrganizationUsers(
                    this.organization.value
                );
                const users = result.isOk ? result.value : [];
                users.forEach((user) => this.users.set(user.id, user));
            } catch (e) {
                throw e;
            } finally {
                this.loader.stop();
            }
        };

        // Lifecycle.

        /** Initial page mount. */
        onMounted(async () => {
            await this.fetchNotes();
        });
    }

    /**
     * Define the callbacks.
     */
    registerSuccessEventListener() {
        /**
         * Convert note indices into rows.
         * @param {Note.Model} index
         * @returns {Note.Row}
         */
        const toNodeRow = (index) => {
            const author = this.users.get(index.authorId);
            return {
                id: index.id,
                accountId: index.accountId,
                title: index.title,
                author,
                content: index.content,
                associationType: index.associationType,
                associationPath: index.associationPath,
                startDate: index.startDate,
                endDate: index.endDate,
                parentHierarchyId: index.parentHierarchyId,
                parentLocationId: index.parentLocationId,
            };
        };

        /** Perform index side-effects. */
        this.index.onSuccess(({ data }) => {
            // Update the grid.
            const rows = data.map(toNodeRow);
            this.grid.updateRowData({ data: rows });
            this.send.success(`Fetched ${rows.length} note(s) successfully.`);
        });

        this.index.onError(({ reason }) => {
            this.send.error('Failed to fetch notes.', String(reason));
        });
    }

    /**
     * Define the callbacks.
     * @param {ReturnType<useNoteForms>} forms
     */
    registerFormEventListeners(forms) {
        /** Handle closing of the add form. */
        this.forms.add.onCancel(({ reason, formData }) => {
            // Clear the form state and close the modal.
            forms.state.add.clear();
            this.modals.close('add');
            this.send.warning(`Add note cancelled. Nothing will be created.`);
        });

        /** Handle closing of the edit form. */
        this.forms.edit.onCancel(({ reason, formData }) => {
            // Clear the form state and close the modal.
            forms.state.edit.clear();
            this.modals.close('edit');
            if (forms.state.edit.isEditing.value) {
                this.send.warning(
                    `Edit note cancelled. Nothing will be updated.`
                );
            }
        });

        /** Handle closing of the delete form. */
        this.forms.delete.onCancel(({ reason, formData }) => {
            // Clear the form state and close the modal.
            forms.state.delete.clear();
            this.modals.close('delete');
            this.send.warning(
                `Delete note cancelled. Nothing will be removed.`
            );
        });

        /**
         * Create a form request.
         * @param {Optional<Note.Form.AddData, 'form'>} formData
         * @return {Note.Request.CreateResource}
         */
        this.createRequestFromFormData = (formData) => {
            // Resolve start date and end date.
            const start_date =
                formData.dateStartZ?.length > 0 ? formData.dateStartZ : null;
            const end_date =
                formData.dateEndZ?.length > 0 ? formData.dateEndZ : null;

            // Resolve association details.
            const associationType = formData.associationType;
            const parent_location_id =
                associationType === AssociationType.Location
                    ? formData.parentLocationId
                    : null;
            const parent_hierarchy_id =
                associationType === AssociationType.Hierarchy
                    ? formData.parentHierarchyId
                    : null;

            return {
                account_id: this.account.value?.id,
                title: formData.title,
                content: formData.content,
                parent_location_id,
                parent_hierarchy_id,
                start_date,
                end_date,
                is_visible: true,
            };
        };

        /**
         * Create a form request.
         * @param {Optional<Note.Form.EditData, 'form'>} formData
         * @return {Note.Request.UpdateResource}
         */
        this.updateRequestFromFormData = (formData) => {
            // Resolve start date and end date.
            const start_date =
                formData.dateStartZ?.length > 0 ? formData.dateStartZ : null;
            const end_date =
                formData.dateEndZ?.length > 0 ? formData.dateEndZ : null;

            // Resolve association details.
            const associationType = formData.associationType;
            const parent_location_id =
                associationType === AssociationType.Location
                    ? formData.parentLocationId
                    : null;
            const parent_hierarchy_id =
                associationType === AssociationType.Hierarchy
                    ? formData.parentHierarchyId
                    : null;

            return {
                id: formData.id,
                account_id: this.account.value?.id,
                title: formData.title,
                content: formData.content,
                parent_location_id,
                parent_hierarchy_id,
                start_date,
                end_date,
                is_visible: true,
            };
        };

        /** Handle submission of the add form. */
        this.forms.add.onSubmit(async ({ formData }) => {
            try {
                // Prepare the request.
                const request = this.createRequestFromFormData(formData);

                // Send the delete request.
                const result = await createNote(request);

                // Check for errors.
                if (result.isErr) {
                    throw result.error;
                }

                // Refresh if note was created successfully.
                if (result.isOk && result.value != null) {
                    // Clear the form state and close the modal.
                    forms.state.add.clear();
                    // When successful.
                    this.modals.close('add');
                    // Send successful toast.
                    const title = result.value?.title ?? '';
                    const slug =
                        title.length > 8
                            ? `${title.substring(0, 8)}...`
                            : title;
                    this.send.success(`Added note "${slug}" successfully.`);
                    // Refresh the notes.
                    await this.fetchNotes();
                }
            } catch (e) {
                this.send.error(`Failed to add note.`, String(e));
            }
        });

        /** Handle submission of the add form. */
        this.forms.edit.onSubmit(async ({ formData }) => {
            try {
                // Prepare the request.
                const request = this.updateRequestFromFormData(formData);

                // Send the delete request.
                const result = await updateNoteById(request);

                // Check for errors.
                if (result.isErr) {
                    throw result.error;
                }

                // Refresh if note was created successfully.
                if (result.isOk && result.value != null) {
                    // Clear the form state and close the modal.
                    forms.state.edit.clear();
                    // When successful.
                    this.modals.close('edit');
                    // Send successful toast.
                    const title = result.value?.title ?? '';
                    const slug =
                        title.length > 8
                            ? `${title.substring(0, 8)}...`
                            : title;
                    this.send.success(`Updated note "${slug}" successfully.`);
                    // Refresh the notes.
                    await this.fetchNotes();
                }
            } catch (e) {
                this.send.error(`Failed to update note.`, String(e));
            }
        });

        /** Handle submission of the delete form. */
        this.forms.delete.onSubmit(async ({ formData }) => {
            try {
                // Send the delete request.
                const result = await deleteNoteById({
                    id: formData.id,
                    account_id: this.account.value.id,
                });

                // Check for errors.
                if (result.isErr) {
                    throw result.error;
                }

                // Get the result.
                if (result.isOk && result.value === true) {
                    // Clear the form state and close the modal.
                    forms.state.delete.clear();
                    // When successful.
                    this.modals.close('delete');
                    // Send successful toast.
                    this.send.success(`Deleted note successfully.`);
                    // Refresh the notes.
                    await this.fetchNotes();
                }
            } catch (e) {
                this.send.error(`Failed to delete note.`, String(e));
            }
        });

        /** Handle grid action click. */
        this.grid.onButtonClicked(async ({ target, action }) => {
            this.grid.selectedRow.value = target;
            switch (action) {
                case 'view':
                    await this.clickViewButton();
                    break;
                case 'edit':
                    await this.clickEditButton();
                    break;
                case 'delete':
                    this.clickDeleteButton();
                    break;
            }
        });
    }
}

// <!-- COMPOSABLE -->
export const useNoteManager = () => {
    return new Manager();
};

// <!-- DEFAULT -->
export default useNoteManager;
