// <!-- FORWARD EXPORTS -->
export { NotePayload } from '@/payloads/v2/notes/NotePayload';
export { NoteModel } from './NoteModel';

// <!-- IMPORT -->
import { NotePayload } from '@/payloads/v2/notes/NotePayload';
import { NoteModel } from './NoteModel';

// <!-- NAMESPACE EXPORTS -->
export const Note = {
    Payload: NotePayload,
    Model: NoteModel,
};
