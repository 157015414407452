// <!-- API -->
import { ref } from 'vue';
import { HierarchyTreeContext } from './HierarchyTree';
import {
    HierarchyInput,
    HierarchySelector,
    HierarchyTextbox,
    LocationSelector,
} from './HierarchyInput';

// <!-- TYPES -->
// ts-ignore

/**
 * Individual hierarchy node in a larger HierarchyTree.
 */
export class HierarchyTreeNode {
    // #region <!-- PROTECTED MEMBERS -->

    /** @type {HierarchyTreeContext} Underlying, interal shared tree context api. */
    _context = null;

    /** @type {String} Identifier and name prefix, namespace. */
    _ns = null;

    /** @type {V.Ref<Set<'refreshing'|'initialized'>>} Status flag tracker. */
    _status = ref(new Set());

    /** @type {'select'|'text'} Set of current input mode preferences. */
    _mode = 'select';

    /** @type {Map<String, HierarchyInput>} Hierarchy input map. */
    _inputs = new Map([
        ['hierarchy', null],
        ['location', null],
        ['textbox', null],
    ]);

    // #endregion

    // #region <!-- PUBLIC MEMBERS -->

    /** @type {HierarchyTreeNode} Parent node, if present. */
    parent = null;

    /** @type {HierarchyTreeNode} Child node, if present. */
    child = null;

    // #endregion

    // #region <!-- STATIC FACTORY METHODS -->

    /**
     * Create root tree node.
     *
     * @param {HierarchyTreeContext} context Tree context.
     */
    static root(context) {
        return HierarchyTreeNode.create(context, null);
    }

    /**
     * Create new tree node.
     *
     * @param {HierarchyTreeContext} context Tree context.
     * @param {HierarchyTreeNode} parent Parent node
     */
    static create(context, parent) {
        const node = new HierarchyTreeNode(context, context.id);
        if (!!parent) {
            node.parent = parent;
            parent.child = node;
        }
        return node;
    }

    /**
     * Add selector input.
     *
     * @param {HierarchyTreeNode} node
     */
    static addHierarchySelector(node) {
        node.selector = new HierarchySelector(node);
    }

    /**
     * Add location selector input.
     *
     * @param {HierarchyTreeNode} node
     */
    static addLocationSelector(node) {
        node.locationSelector = new LocationSelector(node);
    }

    /**
     * Add textbox input.
     *
     * @param {HierarchyTreeNode} node
     */
    static addTextbox(node) {
        node.textbox = new HierarchyTextbox(node);
    }

    // #endregion

    // #region <!-- CONSTRUCTOR -->

    /**
     * Creates hierarchy tree node instance.
     *
     * @param {HierarchyTreeContext} context Hierarchy tree context.
     * @param {String} [namespace] Identifier prefix.
     */
    constructor(context, namespace = null) {
        if (!context) {
            throw new TypeError(
                'Cannot instantiate node without a backing HierarchyTreeContext instance.'
            );
        }
        this._context = context;
        this.namespace = namespace;
    }

    // #endregion

    // #region <!-- PROPERTIES (DATA) -->

    /**
     * Hierarchy tree context reference.
     */
    get context() {
        return this._context;
    }

    /**
     * Gets the identifier.
     */
    get id() {
        return `${this.namespace}-${this.depth}`;
    }

    /** @returns {HierarchySelector} HTMLSelectElement wrapper. */
    get selector() {
        const input = /** @type {HierarchySelector} */ (
            this._inputs.get('hierarchy')
        );
        return input;
    }

    set selector(input) {
        this._inputs.set('hierarchy', input);
    }

    /** @type {HierarchyTextbox} Text input settings. */
    get textbox() {
        const input = /** @type {HierarchyTextbox} */ (
            this._inputs.get('textbox')
        );
        return input;
    }

    set textbox(input) {
        this._inputs.set('textbox', input);
    }

    /** @returns {LocationSelector} HTMLSelectElement wrapper. */
    get locationSelector() {
        const input = /** @type {LocationSelector} */ (
            this._inputs.get('location')
        );
        return input;
    }

    set locationSelector(input) {
        this._inputs.set('location', input);
    }

    /**
     * Get the identifier prefix.
     */
    get namespace() {
        return this._ns;
    }

    /**
     * Assigns identifier partial.
     */
    set namespace(value) {
        this._ns = String(value).trim();
    }

    /**
     * Get the node depth.
     * @returns {Number}
     */
    get depth() {
        return this.isRoot ? 0 : this.parent.depth + 1;
    }

    /** Get the hierarchy path. */
    get path() {
        const previous = this.isRoot ? null : this.parent.path;
        const segments = !!previous ? [previous] : [];
        if (this.selector.active) {
            const missing = '<Missing Hierarchy>';
            const selected = this.selector?.getSelectedHierarchy();
            const segment =
                !!selected && !!selected?.name ? selected.name : null;
            segments.push(segment ?? missing);
        } else {
            const missing = '<New Hierarchy>';
            const value = this.textbox.value;
            const segment = value === null || value === '' ? null : value;
            segments.push(segment ?? missing);
        }

        // Return the joined path segments.
        return segments.join('/').trim();
    }

    /**
     * Get this node's ancestors along with this instance.
     *
     * @returns {HierarchyTreeNode[]} Hierarchy resource.
     */
    get ancestorsAndSelf() {
        try {
            console.groupCollapsed(`[get::ancestor::nodes] (w/ self)`);
            return [...this.ancestors, this];
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Get this node's ancestors.
     *
     * @returns {HierarchyTreeNode[]} Hierarchy resource.
     */
    get ancestors() {
        try {
            console.groupCollapsed(`[get::ancestor::nodes] (w/o self)`);
            const result = [...this.yieldAncestorNodes(this)];
            return result;
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Get this node's descendants along with this instance.
     *
     * @returns {HierarchyTreeNode[]} Hierarchy resource.
     */
    get descendantsAndSelf() {
        try {
            console.groupCollapsed(`[get::descendant::nodes] (w/ self)`);
            return [this, ...this.descendants];
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Get this node's descendants.
     *
     * @returns {HierarchyTreeNode[]} Hierarchy resource.
     */
    get descendants() {
        try {
            console.groupCollapsed(`[get::descendant::nodes] (w/o self)`);
            const result = [...this.yieldDesendantNodes(this)];
            return result;
        } finally {
            console.groupEnd();
        }
    }

    // #endregion

    // #region <!-- PROPERTIES (INCLUDES) -->

    /** Is the node the parent-less? */
    get isRoot() {
        return !this.hasParent;
    }

    /** Is the node the child-less? */
    get isLeaf() {
        return !this.hasChild;
    }

    /** Does the node have a parent? */
    get hasParent() {
        return !!this.parent;
    }

    /** Does the node have a child? */
    get hasChild() {
        return !!this.child;
    }

    /** Does the node input prefer select mode when possible? */
    get preferSelectMode() {
        return this._mode === 'select';
    }

    /**
     * Assign select mode preference.
     *
     * @param {Boolean} value If `true`, set to `select` mode.
     */
    set preferSelectMode(value) {
        const previous = this._mode;
        const next = !!value && value === true ? 'select' : 'text';
        this._mode = next;
        this.onInputModeChanged({ previous, current: this._mode });
    }

    /** Does the node input prefer text mode when possible? */
    get preferTextMode() {
        return this._mode === 'text';
    }

    /**
     * Assign text mode preference.
     *
     * @param {Boolean} value If `true`, set to `select` mode.
     */
    set preferTextMode(value) {
        const previous = this._mode;
        const next = !!value && value === true ? 'text' : 'select';
        this._mode = next;
        this.onInputModeChanged({ previous, current: this._mode });
    }

    /** Does the node force text mode currently? */
    get forceTextMode() {
        // Text mode is forced if the following is true:
        // - Node has parent reference.
        //   - Node parent is using text.
        return this.isRoot || this.parent.textbox === null
            ? false
            : this.parent.textbox.active;
    }

    // #endregion

    // #region <!-- METHODS (EVENTS) -->

    /**
     * Event invoked after the input mode is successfully changed.
     *
     * @param {Object} state Previous and current state object.
     * @param {'select'|'text'} state.previous Previous input mode, prior to change.
     * @param {'select'|'text'} state.current Current input mode, after change.d
     */
    onInputModeChanged(state) {
        try {
            console.groupCollapsed(
                `[change::input::mode] @ ${new Date().toLocaleString()}`
            );
            if (this.hasChild) {
                this.child.onInputModeChanged(state);
            }
            if (state.previous === state.current) {
                console.warn(`No change in input mode detected.`);
                return;
            } else {
                console.dir(state);
                if (this.selector !== null) {
                    if (state.current === 'select') {
                        this.selector.activate();
                    } else {
                        this.selector.deactivate();
                    }
                }
                if (this.textbox !== null) {
                    if (state.current === 'text') {
                        this.textbox.activate();
                    } else {
                        this.textbox.deactivate();
                    }
                }
            }
        } finally {
            console.dir({
                selected: this?.selector?.value,
                text: this?.textbox?.value,
            });
            console.groupEnd();
        }
    }

    // #endregion

    // #region <!-- METHODS (ITERATORS) -->

    /**
     * Yield all desendants (children until leaf...).
     *
     * @param {HierarchyTreeNode} node Hierarchy node.
     */
    *yieldDesendantNodes(node) {
        if (node.hasChild) {
            yield node.child;
            yield* node.yieldDesendantNodes(node.child);
        }
        return;
    }

    /**
     * Yield all ancestors (parents until root...).
     *
     * @param {HierarchyTreeNode} node Hierarchy node.
     */
    *yieldAncestorNodes(node) {
        if (node.hasParent) {
            yield* node.yieldAncestorNodes(node.parent);
            yield node.parent;
        }
        return;
    }

    // #endregion
}

// EXPOSE
export default {
    HierarchyTreeNode,
};
