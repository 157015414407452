<template>
    <div class="flex main-section">
        <div class="flex-grow overflow-y-auto print:overflow-y-visible pb-6">
            <div class="border-b border-gray-200 outer-border">
                <div class="max-w-7xl mx-auto px-5 pt-6 sm:pt-0">
                    <SubNav :subTabs="tabs" />
                    <PageTitle></PageTitle>
                </div>
            </div>
            <slot name="mainContent">
                <!-- FALLBACK CONTENT -->
                Note Manager Content
            </slot>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onBeforeMount } from 'vue';
    import { useRouter } from 'vue-router';

    // <!-- COMPONENTS -->
    import SubNav from '@components/SubNav.vue';
    import PageTitle from '@components/PageTitle.vue';

    // <!-- COMPOSABLES -->
    import { useTabDefs } from '@/hooks/useTabDefs';
    import { useExpiredSubscriptionGate } from '@/hooks/gates';
    import { useNARAFeature } from '@/utils/features/';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'NoteManagerContainer',
        components: {
            SubNav,
            PageTitle,
        },
        props: {
            /** Collection of {@link Tab} items used to define the tabs. */
            tabs: {
                /** @type {import('vue').PropType<Tab[]>} */
                type: Array,
                default: () => [
                    { name: 'Notes', to: '/notes', current: false },
                ],
            },
        },
        setup(props) {
            // Get the router.
            const router = useRouter();
            // Create the tab functionality.
            const { updateCurrentTabByRoute } = useTabDefs(props, router);

            // Expiration guards.
            const { isSuperUser, isCurrentSubscriptionExpired } =
                useExpiredSubscriptionGate();
            const { isNARADisabled } = useNARAFeature();

            // Regsiter lifecycle.
            onBeforeMount(async () => {
                // Update the tab route.
                updateCurrentTabByRoute();

                // Check if the user subscription is expired.
                if (
                    !isSuperUser.value &&
                    isCurrentSubscriptionExpired.value &&
                    isNARADisabled.value
                ) {
                    return await router.push('/expired-subscription');
                }
            });
        },
    });
</script>

<style scoped>
    .main-section {
        min-height: calc(100vh - 4rem);
    }

    .outer-border div {
        border: 0;
    }

    @media print {
        .main-section {
            height: auto;
        }
    }
</style>
