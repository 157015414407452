// <!-- API -->
import { ref, computed } from 'vue';
import { createEventHook, resolveUnref } from '@vueuse/shared';

// <!-- CLASS -->
/**
 * Defines the Loader class.
 * @implements {Status.Loader}
 */
class Loader {
    /**
     * Define the event hooks.
     */
    static defineEvents() {
        // Event Hooks.
        /** @type {Vue.EventHook<{ to: Status.State }>} */
        const changed = createEventHook();
        /** @type {Vue.EventHook<void>} */
        const started = createEventHook();
        /** @type {Vue.EventHook<void>} */
        const stopped = createEventHook();

        return {
            changed,
            started,
            stopped,
        };
    }

    /**
     * Define the reactive state.
     * @param {Status.State} initialState
     */
    static defineState(initialState = 'idle') {
        // Reactivity.
        /** @type {Vue.Ref<'idle' | 'loading'>} */
        const status = ref(initialState);
        const isIdle = computed(() => status.value === 'idle');
        const isLoading = computed(() => status.value === 'loading');

        return {
            status,
            isIdle,
            isLoading,
        };
    }

    /**
     * Instantiate a Loader class.
     * @param {Status.State} initialState
     */
    constructor(initialState = 'idle') {
        const { changed, started, stopped } = Loader.defineEvents();
        const { status, isIdle, isLoading } = Loader.defineState();

        // Event Triggers.
        this.change = changed.trigger;
        this.start = started.trigger;
        this.stop = stopped.trigger;

        // Event Listeners.
        this.onChanged = changed.on;
        this.onStarted = started.on;
        this.onStopped = stopped.on;

        // Reactivity.
        this.isIdle = isIdle;
        this.isLoading = isLoading;

        // Methods
        this.setIdle = () => this.change({ to: 'idle' });
        this.setLoading = () => this.change({ to: 'loading' });

        /** @type {(state: Status.State) => boolean} */
        this.is = (state) => status.value === state;

        // Lifecycle
        this.onChanged(({ to: state }) => (status.value = state));

        this.onStarted(() => {
            if (isLoading.value !== true) {
                this.setLoading();
            }
        });

        this.onStopped(() => {
            if (isLoading.value == true) {
                this.setIdle();
            }
        });
    }
}

// <!-- COMPOSABLE -->
/**
 * Define the loader.
 * @param {Vue.Ref<Status.State> | Status.State} [initialState]
 */
export const useLoader = (initialState = 'idle') => {
    const props = resolveUnref(initialState);
    return new Loader(props);
};

// <!-- EXPORTS -->
export default useLoader;
