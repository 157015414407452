// <!-- UTILITIES -->
import { createEventHook } from '@vueuse/core';

// <!-- TYPES -->
/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef UseColumnResizedEventReturn
 * @prop {import('@vueuse/core').EventHook<Events.ColumnResizedEvent<TData>>} columnResized `ColumnResizedEvent` hook that will trigger when the grid column resizes.
 * @prop {import('@vueuse/core').EventHookOn<Events.ColumnResizedEvent<TData>>} onColumnResized Invoke callback that will consume the `ColumnResizedEvent` when the `columnResized` event is triggered.
 * @prop {import('@vueuse/core').EventHookTrigger<Events.ColumnResizedEvent<TData>>} resizeColumn Trigger `columnResized` event hook.
 */

/**
 * Define event hook.
 * @template [TData=any] AgGrid row node data type.
 * @returns {UseColumnResizedEventReturn<TData>}
 */
export const useColumnResizedEvent = () => {
    const columnResized = createEventHook();
    return {
        columnResized,
        resizeColumn: columnResized.trigger,
        onColumnResized: columnResized.on,
    };
};

// <!-- DEFAULT -->
export default useColumnResizedEvent;
